import { makeStyles } from '@material-ui/core/styles'

import { beige } from '../utils/colors'

export const buttonStyles = makeStyles((theme) => ({
    buttonsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    saveButton: {
        marginRight: theme.spacing(1),
        padding: theme.spacing(1),
        borderWidth: 1,
        backgroundColor: beige,
        borderColor: '#28a745',
        borderRadius: 5,
        borderStyle: 'solid',
        color: '#28a745',
        textAlign: 'center',
        '&:hover, &:focus': {
            backgroundColor: '#28a745',
            color: '#FFFFFF',
        }
    },
    alertButton: {
        marginRight: theme.spacing(1),
        padding: theme.spacing(1),
        borderWidth: 1,
        backgroundColor: beige,
        borderColor: '#ffc107',
        borderRadius: 5,
        borderStyle: 'solid',
        color: '#ffc107',
        textAlign: 'center',
        '&:hover, &:focus': {
            backgroundColor: '#ffc107',
            color: '#FFFFFF',
        }
    },
    cancelButton: {
        marginRight: theme.spacing(1),
        padding: theme.spacing(1),
        borderWidth: 1,
        backgroundColor: beige,
        borderColor: '#dc3545',
        borderRadius: 5,
        borderStyle: 'solid',
        color: '#dc3545',
        textAlign: 'center',
        '&:hover, &:focus': {
            backgroundColor: '#dc3545',
            color: '#FFFFFF',
        }
    },
    uploadButton: {
        width: '100%',
        marginTop: 20,
        marginRight: theme.spacing(1),
        borderWidth: 1,
        backgroundColor: beige,
        borderColor: '#063354',
        borderRadius: 5,
        borderStyle: 'solid',
        color: '#063354',
        textAlign: 'center',
        '&:hover, &:focus': {
            backgroundColor: '#063354',
            color: '#FFFFFF',
        }
    },
    editQuestionButton: {
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        margin: 8,
        transition: '0.3s',
        msTransition: 'ease-in',
        padding: 5,
        borderColor: '#063354',
        borderRadius: 5,
        '&:hover, &:focus': {
            backgroundColor: '#063354',
            color: '#FFFFFF',
        }
    }
}))
