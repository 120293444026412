/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import '../styles/Home.css'

import React, { useEffect, useState } from 'react'

import { deletePuzzle, listAdminPuzzles, listPuzzles } from '../../../utils/services/puzzle-services'
import styles from '../styles/puzzleGlary.module.css'
import GlaryCard from './glaryCard'
import Loading from './loading'
import BackSvg from './svg/backSvg'
import LoadingSvgAnim from './svg/loadingSvgAnim'

export default function PuzzleGlary({
    backButtonMain,
    user_id,
    profile,
    handleEditPuzzle,
}) {
    const [puzzles, setPuzzles] = useState([])
    const [loadingPuzzles, setLoadingPuzzles] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [deleting, setDeleting] = useState(null)
    const [adminPuzzles, setAdminPuzzles] = useState(null)
    const [deletePopup, setDeletePopup] = useState(null)

    useEffect(() => {
        if (profile) {
            fetchPuzzles(user_id)
        }
    }, [profile, user_id])

    // function sleep(ms) {
    //     return new Promise((resolve) => setTimeout(resolve, ms))
    // }

    const fetchPuzzles = async (user_id) => {
        setLoadingPuzzles(true)
        if (user_id) {
            if (!profile.is_admin) {
                const puzzles = await listPuzzles()
                if (puzzles.error) {
                    console.log(puzzles)
                    setErrorMessage(puzzles.error)
                    return
                }
                const newPuzzles = puzzles.filter(
                    (oldPuzzle) => oldPuzzle.teacher.id === profile.profile_id
                )
                console.log(newPuzzles)
                setPuzzles(newPuzzles)
            } else {
                const puzzles = await listAdminPuzzles()
                if (puzzles.error) {
                    console.log(puzzles)
                    setErrorMessage(puzzles.error)
                    return
                }
                const newPuzzles = puzzles.filter(
                    (oldPuzzle) => oldPuzzle.teacher.id === profile.profile_id
                )
                console.log(newPuzzles)
                setPuzzles(newPuzzles)
                setAdminPuzzles(true)
            }
        } else {
            console.log('Trigger')
            const puzzles = await listAdminPuzzles()
            if (puzzles.error) {
                console.log(puzzles)
                setErrorMessage(puzzles.error)
                return
            }
            const newPuzzles = puzzles.filter((oldPuzzle) => oldPuzzle.show === true)
            console.log(newPuzzles)
            setPuzzles(newPuzzles)
            setAdminPuzzles(true)
        }
        setLoadingPuzzles(null)
    }

    const handleDelete = async (id) => {
        setDeleting(id)
        const response = await deletePuzzle(id)
        // const { data, error } = await supabase
        //   .from(tableName)
        //   .delete()
        //   .eq("id", id);
        // if (error) {
        //   setErrorMessage("Oops some error.");
        //   setTimeout(() => {
        //     setErrorMessage(null);
        //   }, 2000);
        //   return;
        // }

        console.log(response)
        // await sleep(2000);
        setDeleting(false)
        setPuzzles((old) => {
            const newOne = [...old]
            return newOne.filter((pice) => pice.id !== id)
        })
        setDeletePopup(null)
    }

    if (loadingPuzzles) return <Loading />

    if (puzzles.length <= 0) {
        return (
            <div className={styles.noPuzzles}>
                <h1>Du har ingen gemte puslespil</h1>
                <button type="button" className={`button ${styles.backButton}`} onClick={backButtonMain}>
                    <BackSvg /><span className="button">tilbage</span>
                </button>
            </div>
        ) 
    }

    return (
        <div className={styles.container}>
            {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
            {puzzles.map((puzzle, index) => (
                <GlaryCard
                    key={`card-${index}`}
                    puzzle={puzzle}
                    handleDelete={handleDelete}
                    deleting={deleting}
                    user_id={user_id}
                    admin={adminPuzzles}
                    handleEditPuzzle={handleEditPuzzle}
                    setDeletePopup={setDeletePopup}/>
            ))}
            <button type="button" className={`button ${styles.backButton}`} onClick={backButtonMain}>
                <BackSvg /> <span className="button">tilbage</span>
            </button>
            {deletePopup && (
                <div className={styles.deletePopupBg}>
                    <div className={styles.deletePopup}>
                        <h1>Er du sikker på at du vil slette?</h1>
                        <div className={styles.deletePopupButtonsCont}>
                            <button
                                type="button"
                                className={styles.deletePopupButtons}
                                onClick={() => handleDelete(deletePopup)}>
                                {deleting && <LoadingSvgAnim />}
                                Slet
                            </button>
                            <button
                                type="button"
                                className={styles.deletePopupButtons}
                                onClick={() => setDeletePopup(null)}>
                                Slet ikke
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
