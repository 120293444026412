/* eslint-disable @typescript-eslint/no-unused-vars */
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import * as React from 'react'

import { DeleteModal } from '../../components/molecules/DeleteModal'
import { ManageEntitiesViewTemplate } from '../../components/organisms/ManageEntitiesViewTemplate'
import { HomeworkType } from '../../types/types'
import { deleteHomework, getHomeworks } from '../../utils/services/homework-services'
import { fireWrap, wrap } from '../../utils/wrapper'
import { Loading } from '../Loading'

const filterHomeWorks = (filter: string, item: HomeworkType) => {
    return item.teacher.firstName.toLowerCase().includes(filter.toLowerCase())
        || item.name.toLowerCase().includes(filter.toLowerCase())
        || !!item.url?.toLowerCase().includes(filter.toLowerCase())
}
export const HomeworksPage: React.FC = () => {

    const [items, setItems] = React.useState<HomeworkType[]>([])
    const [deletingItems, setDeletingItems] = React.useState<HomeworkType[]>([])
    const [busy, setBusy] = React.useState(false)

    const handleDelete = async () => {
        await wrap(async () => {
            await Promise.all(
                deletingItems.map((item) => deleteHomework(item.name))
            )
        },
        {
            operation: 'deleting homework(s)',
            stateFunc: setBusy
        })

        const newList = items.filter((item) => !deletingItems.includes(item))
        setItems(newList)
        setDeletingItems([])
    }

    React.useEffect(() => {
        fireWrap(async () => {
            setItems(await getHomeworks())
        },
        {
            operation: 'loading homeworks',
            stateFunc: setBusy
        })
    }, [])
    
    if (busy) {
        return (
            <Loading/>
        )
    }
    return (
        <ManageEntitiesViewTemplate
            searchComponent
            filterItem={filterHomeWorks}
            backgroundColor="#AB702B"
            icon={<ImportContactsIcon style={{ color: '#FFFFFF' }}/>}
            title="Lektierlinks"
            items={items}
            headers={['Lærer', 'Navn', 'Udløbsdato', 'URL', 'Antal Videoer']}
            convertItem={(h) => [h.teacher.firstName, h.name, h.expirationDate, h.url, h.numberOfVideos]}
            canDelete
            onDelete={setDeletingItems}>
            <DeleteModal
                onConfirmAction={handleDelete}
                onCancelAction={() => setDeletingItems([])}
                confirmActionButtonText="Ja, helt sikker"
                cancelActionButtonText="Nej"
                headerText="Er du sikker på at du vil slette denne lektie?"
                isOpen={deletingItems.length > 0}
                onClose={() => setDeletingItems([])}/>
        </ManageEntitiesViewTemplate>
    )
}
