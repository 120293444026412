import { API } from 'aws-amplify'

import { PresentationType } from '../../types/types'

export const getPresentation = async (): Promise<PresentationType> => {
    return API.get('api', '/presentation', {})
}

export const savePresentation = async (params: PresentationType): Promise<PresentationType> => {
    return API.post('api', '/presentation', { body: params })
}

export const updatePresentation = async (params: PresentationType): Promise<PresentationType> => {
    return API.put('api', '/presentation', {
        body: params
    })
}
