import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import * as React from 'react'

import { DeleteModal } from '../../components/molecules/DeleteModal'
import { EditSoundLibraryModal } from '../../components/organisms/EditSoundLibraryModal'
// import { EditSoundLibraryModal } from '../../components/organisms/EditSoundLibraryModal'
import { ManageEntitiesViewTemplate } from '../../components/organisms/ManageEntitiesViewTemplate'
import { SoundType, UpdateSoundType } from '../../types/types'
import { sortItemsByCreatedAtString } from '../../utils/helper'
import {
    createSound, deleteSound, getSounds, updateSound
} from '../../utils/services/sound-services'
import { fireWrap, wrap } from '../../utils/wrapper'
import { Loading } from '../Loading'

export const SoundsLibraryPage: React.FC = () => {

    const [creating, setCreating] = React.useState(false)
    const [items, setItems] = React.useState<SoundType[]>([])
    const [editedItem, setEditedItem] = React.useState<SoundType>()
    const [deletingItems, setDeletingItems] = React.useState<SoundType[]>([])
    const [busy, setBusy] = React.useState(false)

    const convertItem = (v: SoundType) => {
        return [
            <p style={{ maxWidth: '80%' }}>{v.title}</p>,
            <p style={{ maxWidth: '20%' }}>{v.isCorrectFeedback ? 'True' : 'False'}</p>,
        ]
    }

    const handleDelete = async () => {
        await wrap(async () => {
            await Promise.all(
                deletingItems.map((item) => deleteSound(item.id))
            )

            setItems(
                items.filter((item) => !deletingItems.includes(item))
            )
            setDeletingItems([])
        }, {
            operation: 'deleting sound(s)',
            showSuccess: true,
            stateFunc: setBusy
        })
    }

    const handleFinished = async (save: boolean, item: UpdateSoundType, isNew: boolean) => {

        if (save && item) {
            await wrap(async () => {
                if (isNew) {
                    setItems([await createSound(item), ...items])
                } else {
                    const updated = await updateSound(editedItem!.id, item)
                    setItems(
                        items.map(
                            (i) => (i.id === editedItem!.id ? updated : i)
                        )
                    )
                }
            }, {
                operation: isNew ? 'Creating Sound' : 'Updating Sound',
                showSuccess: true,
                stateFunc: setBusy
            })
        }

        setEditedItem(undefined)
        setCreating(false)
    }

    React.useEffect(() => {
        fireWrap(async () => {
            const sounds = await getSounds()
            setItems(sortItemsByCreatedAtString(sounds))
        }, {
            operation: 'loading sounds',
            stateFunc: setBusy
        })
    }, [])

    if (busy) {
        return (
            <Loading />
        )
    }
    return (
        <ManageEntitiesViewTemplate
            searchComponent
            backgroundColor="#278753"
            icon={<HelpOutlineIcon style={{ color: '#FFFFFF' }} />}
            title="Lydbibliotek"
            items={items}
            headers={['Navn', 'Feedback']}
            convertItem={convertItem}
            canCreate
            canEdit
            canDelete
            onCreate={() => setCreating(true)}
            onDelete={setDeletingItems}
            onEdit={setEditedItem}>
            <DeleteModal
                onConfirmAction={handleDelete}
                onCancelAction={() => setDeletingItems([])}
                confirmActionButtonText="Ja, helt sikker"
                cancelActionButtonText="Nej"
                headerText="Er du sikker på at du vil slette denne lyd?"
                isOpen={deletingItems.length > 0}
                onClose={() => setDeletingItems([])} />
            <EditSoundLibraryModal
                item={editedItem}
                isBusy={busy}
                key={editedItem?.id}
                header={editedItem ? 'Rediger Lyd' : 'Opret Lyd'}
                isOpen={!!editedItem || creating}
                isNew={creating}
                onFinish={handleFinished} />
        </ManageEntitiesViewTemplate>
    )

}
