import { API } from 'aws-amplify'

import { TeacherType } from '../../types/types'

type CreateTeacherUserData = {
    email: string
    password: string
}

export const createTeacherUser = async (params: CreateTeacherUserData): Promise<any> => {
    return API.post('api', '/teachers', {
        body: params
    })
}

export const getTeachers = async (): Promise<TeacherType[]> => {
    return API.get('api', '/teachers', {})
}

export const resetTeacherPassword = async (teacherId: string): Promise<void> => {
    return API.post('api', `/teachers/${teacherId}/reset`, {})
}
export const deleteTeacher = async (teacherId: string): Promise<void> => {
    return API.del('api', `/teachers/${teacherId}`, {})
}
