import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import * as React from 'react'

import { DeleteModal } from '../../components/molecules/DeleteModal'
import { EditPriceModal } from '../../components/organisms/EditPriceModal'
import { ManageEntitiesViewTemplate } from '../../components/organisms/ManageEntitiesViewTemplate'
import { PriceType } from '../../types/types'
import {
    createPrice, deletePrice, getPrices, updatePrice 
} from '../../utils/services/price-services'
import { fireWrap, wrap } from '../../utils/wrapper'
import { Loading } from '../Loading'

export const PricePage: React.FC = () => {

    const [creating, setCreating] = React.useState(false)
    const [items, setItems] = React.useState<PriceType[]>([])
    const [editedItem, setEditedItem] = React.useState<PriceType>()
    const [deletingItems, setDeletingItems] = React.useState<PriceType[]>([])

    const [busy, setBusy] = React.useState(false)

    const handleDelete = async () => {

        await wrap(async () => {
            await Promise.all(
                deletingItems.map((item) => deletePrice(item.id))
            )

            setItems(
                items.filter((item) => !deletingItems.includes(item))
            )
            setDeletingItems([])
        }, {
            operation: 'deleting price(s)',
            showSuccess: true,
            stateFunc: setBusy
        })
    }

    const handleFinished = async (save: boolean, item: { price: number, amountOfMonths: number }, isNew: boolean) => {
        if (save && item) {
            if (isNew) {
                await wrap(async () => {
                    setItems([await createPrice(item), ...items])
                }, {
                    operation: 'creating price',
                    stateFunc: setBusy,
                    showSuccess: true
                })
            } else {
                await wrap(async () => {
                    const cmp = await updatePrice(editedItem!.id, item)
                    setItems(
                        items.map(
                            (i) => (i.id === editedItem!.id ? cmp : i)
                        )
                    )
                }, {
                    operation: 'updating price',
                    stateFunc: setBusy,
                    showSuccess: true
                })
            }
        }

        setEditedItem(undefined)
        setCreating(false)
    }

    React.useEffect(() => {
        fireWrap(async () => {
            setItems(await getPrices())
        }, {
            operation: 'loading prices',
            stateFunc: setBusy
        })
    }, [])

    if (busy) {
        return (
            <Loading/>
        )
    }
    return (
        <ManageEntitiesViewTemplate
            backgroundColor="#473D65"
            icon={<AttachMoneyIcon style={{ color: '#FFFFFF' }}/>}
            title="Priser"
            items={items}
            headers={['Pris', 'Antal Måneder']}
            convertItem={(p) => [p.price, p.amountOfMonths]}
            canCreate
            canEdit
            canDelete
            onCreate={() => setCreating(true)}
            onDelete={setDeletingItems}
            onEdit={setEditedItem}>
            <DeleteModal
                onConfirmAction={handleDelete}
                onCancelAction={() => setDeletingItems([])}
                confirmActionButtonText="Ja, helt sikker"
                cancelActionButtonText="Nej"
                headerText="Er du sikker på at du vil slette denne Pris?"
                isOpen={deletingItems.length > 0}
                onClose={() => setDeletingItems([])}/>
            <EditPriceModal
                isBusy={busy}
                header={editedItem ? 'Rediger Pris' : 'Opret Pris'}
                isOpen={!!editedItem || creating}
                price={editedItem?.price}
                amountOfMonths={editedItem?.amountOfMonths}
                isNew={creating}
                onFinish={handleFinished}/>
        </ManageEntitiesViewTemplate>
    )
}
