import { TextField } from '@material-ui/core'
import React from 'react'

import { lightGrey } from '../../utils/colors'

type Props = {
    error?: boolean
    label?: string;
    underText?: string;
    type?: string;
    onChange?: (value: any) => void;
    onSubmit?(): void;
    onBlur?(): void;
    value?: string;
    onKeyPress?: (value: string, event: React.KeyboardEvent<HTMLDivElement>) => void
    fullWidth?: boolean,
    disabled?: boolean
}
export const TextInputTemplate: React.FC<Props> = ({
    error,
    label,
    underText,
    type,
    onChange, 
    onSubmit,
    onBlur,
    value,
    onKeyPress,
    fullWidth,
    disabled
}) => {
    const actuallyError = error && !!value && value.length > 0
    return (
        <>
            <div style={{ 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'flex-start', 
                marginBottom: 20,
                marginTop: 20,
                width: '100%'
            }}>
                <TextField
                    error={actuallyError}
                    onKeyPress={(e) => onKeyPress && onKeyPress(e.key, e)}
                    onChange={(e) => onChange && onChange(e.target.value)}
                    onSubmit={onSubmit}
                    onBlur={onBlur}
                    label={label}
                    fullWidth={fullWidth}
                    value={value}
                    disabled={disabled}
                    variant="outlined"
                    size="small"
                    helperText={actuallyError ? 'Invalid value' : ''}
                    type={type}/>
                <small style={{ paddingTop: 5, fontWeight: 300, color: lightGrey }}>{underText}</small>
            </div>
        </>
    )
}
