/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
import '../styles/globals.css'
import '../styles/Home.css'

import React, { useEffect, useRef, useState } from 'react'

import { BodyWithTopBar } from '../../../components/wrappers/BodyWithTopBar'
import { uploadFile } from '../../../utils/services/file-services'
import {
    createAdminPuzzle, createPuzzle, getPuzzleById, updatePuzzle
} from '../../../utils/services/puzzle-services'
import CopyUrlBox from '../components/copyUrlBox'
import Loading from '../components/loading'
import Logo from '../components/logo'
import PuzzleEditor from '../components/puzzleEditor'
import PuzzleGlary from '../components/puzzleGlary'
import BackSvg from '../components/svg/backSvg'
import LoadingSvgAnim from '../components/svg/loadingSvgAnim'
import { getCurrentUser } from '../utils/apis'
import { sizeOptions } from '../utils/config'
import { setEquation, setSingleEquation } from '../utils/puzzleData'

export default function PuzzleHome() {
    const [createdUrl, setCreatedUrl] = useState(null)
    const [openedMenu, setOpenedMenu] = useState('main')
    const [profile, setProfile] = useState(null)
    const [puzzleBoxes, setPuzzleBoxes] = useState([])
    const [selectedFile, setSelectedFile] = useState(null)
    const [file, setFile] = useState(null)
    const [selectedSize, setSelectedSize] = useState(null)
    const [puzzleDis, setPuzzleDis] = useState(null)
    const [puzzleName, setPuzzleName] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [activePuzzleId, setActivePuzzleId] = useState(null)
    const [saving, setSaving] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const fileInputRef = useRef(null)

    // Auth setup
    useEffect(() => {
        const fetchProfile = async () => {
            const token = await getCurrentUser()
            const data = token.payload
            if (data) {
                const role = token.payload['cognito:groups'][0]
                const profile_id = token.payload.sub
                let is_admin = false
                if (role === 'admin') is_admin = true
                const newData = { is_admin, profile_id }
                setProfile(newData)
                // console.log(data);
                setIsLoading(false)
            }
        }
        fetchProfile()
    }, [])

    const openCreatePuzzle = (back = false) => {
        setOpenedMenu('puzzle')
        setPuzzleBoxes([])
        setActivePuzzleId(null)
        setPuzzleName(null)
        setFile(null)
        setSelectedFile(null)
        setPuzzleDis(null)
        if (!back) {
            setSelectedSize(null)
        }
    }

    const openMainMenu = () => {
        setOpenedMenu('main')
    }

    const handleCreatePuzzle = (e) => {
        e.preventDefault()
        backButtonToPuzzle()
    }

    const handleEditEquation = (connection_id, first, second, single = false) => {
        let puzzleData = [...puzzleBoxes]
        if (single) {
            puzzleData = setSingleEquation(connection_id, first, puzzleData)
        } else {
            puzzleData = setEquation(connection_id, first, second, puzzleData)
        }
        setPuzzleBoxes(puzzleData)
    }

    const backButtonToPuzzle = () => {
        setOpenedMenu('editor')
    }

    const nextToConfirm = () => {
        setOpenedMenu('confirm')
    }

    const openGeekStar = () => {
        setOpenedMenu('geek')
    }

    const openMy = () => {
        setOpenedMenu('my')
    }

    const handleFileUploadChange = (e) => {
        if (e.target.files.length > 0) {
            const fileName = e.target.files[0].name
            setFile(e.target.files[0])
            setSelectedFile(fileName)
        }
    }

    const handleFileUpload = () => {
        fileInputRef.current.click()
    }

    const handleSelectionChange = (e) => {
        const { value } = e.target
        if (value < 0) {
            //
        } else if (value == 0) {
            setSelectedSize('3x3')
        } else if (value == 1) {
            setSelectedSize('4x4')
        }
    }

    const handleSavePuzzle = async (e) => {
        e.preventDefault()
        if (
            !selectedFile
            || !puzzleName
            || !selectedSize
            || puzzleBoxes.length < 5
        ) {
            setErrorMessage('Fill all the fields.')
            return
        }

        setSaving(true)
        // UploadFile
        const fileResponse = await uploadFile(file)
        console.log(fileResponse)
        const filePath = fileResponse.uri
        const fileKey = fileResponse.key

        // Structure data for upload
        const boxes = JSON.stringify({ data: puzzleBoxes })
        const dataToStore = {
            profile_id: profile.profile_id,
            size: selectedSize,
            name: puzzleName,
            description: puzzleDis,
            image: filePath,
            fileKey,
            show: true,
            boxes,
        }

        // const { data: puzzleData, error: puzzleError } = await supabase
        //   .from(uploadTable)
        //   .insert([
        //     {
        //       profile_id: session.user.id,
        //       size: selectedSize,
        //       name: puzzleName,
        //       description: puzzleDis,
        //       image: filePath,
        //       boxes: { data: puzzleBoxes },
        //     },
        //   ])
        //   .select()
        //   .single();

        // Save Puzzle
        let puzzleData = {}
        console.log(profile.is_admin)
        if (profile.is_admin) {
            if (activePuzzleId) {
                dataToStore.id = activePuzzleId
                console.log(dataToStore)
                puzzleData = await updatePuzzle(dataToStore)
            } else {
                puzzleData = await createAdminPuzzle(dataToStore)
            }
        } else if (activePuzzleId) {
            dataToStore.id = activePuzzleId
            puzzleData = await updatePuzzle(dataToStore)
        } else {
            puzzleData = await createPuzzle(dataToStore)
        }
        console.log(puzzleData)
        if (puzzleData.error) {
            setErrorMessage('Error saving puzzle')
            console.log(puzzleData.error)
            setSaving(null)
            return
        }

        // const { data: fileData, error: fileError } = await supabase.storage
        //   .from("images")
        //   .upload(filePath, file, {
        //     cacheControl: "3600",
        //     upsert: false,
        //   });

        // if (fileError) {
        //   console.log(fileError);
        //   setErrorMessage("Error uploading file");
        //   setSaving(null);
        //   return;
        // }

        // reset
        setCreatedUrl(
            `laerer.geekster.dk/play?id=${puzzleData.id}&type=${profile.is_admin ? 'admin' : 'user'
            }`
        )
        setSaving(null)
        setSelectedSize(null)
        setPuzzleBoxes([])
        setPuzzleName(null)
        setFile(null)
        setSelectedFile(null)
        setPuzzleDis(null)
        setOpenedMenu('main')
    }

    const handleSaveUnFinish = async () => {
        setSaving(true)
        let puzzleData = {}
        let dataToStore = {}
        console.log(profile.is_admin)
        if (profile.is_admin) {
            if (activePuzzleId) {
                dataToStore = await getPuzzleById(activePuzzleId)
                dataToStore.boxes = JSON.stringify({ data: puzzleBoxes })
                puzzleData = await updatePuzzle(dataToStore)
            } else {
                dataToStore = {
                    profile_id: profile.profile_id,
                    size: selectedSize,
                    boxes: { data: puzzleBoxes },
                    show: false,
                }
                dataToStore.boxes = JSON.stringify({ data: puzzleBoxes })
                puzzleData = await createAdminPuzzle(dataToStore)
            }
        } else if (activePuzzleId) {
            dataToStore = await getPuzzleById(activePuzzleId)
            dataToStore.boxes = JSON.stringify({ data: puzzleBoxes })
            dataToStore.id = activePuzzleId
            puzzleData = await updatePuzzle(dataToStore)
        } else {
            dataToStore = {
                profile_id: profile.profile_id,
                size: selectedSize,
                boxes: { data: puzzleBoxes },
                show: false,
            }
            dataToStore.boxes = JSON.stringify({ data: puzzleBoxes })
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            puzzleData = await createPuzzle(dataToStore)
        }
        setSaving(null)
        openCreatePuzzle(true)
    }

    const handleEditPuzzle = async (puzzle) => {
        setActivePuzzleId(puzzle.id)
        setSelectedSize(puzzle.size)
        const boxes = JSON.parse(puzzle.boxes)
        setPuzzleBoxes(boxes.data)
        setPuzzleName(puzzle.name)
        setFile(null)
        setSelectedFile(null)
        setPuzzleDis(puzzle.description)
        setOpenedMenu('editor')
    }

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <BodyWithTopBar>
                    <main className="body main">
                        {openedMenu === 'main' && (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <h1 className="greeting">
                                    Velkommen til Geeksters puslespil: <br /> læg brikkerne vha.
                                    matematik
                                </h1>
                                {createdUrl && <p className="message">Puslespil gemt!</p>}
                                {createdUrl && <CopyUrlBox url={createdUrl} />}
                                <div className="menu">
                                    <button type="button" className="button buttons" onClick={openCreatePuzzle}>
                                        Lav nyt puslespil
                                    </button>
                                    <button type="button" className="button buttons" onClick={openMy}>
                                        Mine puslespil
                                    </button>
                                    <button type="button" className="button buttons" onClick={openGeekStar}>
                                        Geeksters puslespil
                                    </button>
                                </div>
                            </div>
                        )}
                        {openedMenu === 'puzzle' && (
                            <>
                                <h1 className="greeting">Opret nyt puslespil</h1>
                                <form onSubmit={handleCreatePuzzle} className="menu">
                                    <button
                                        type="button"
                                        className="backButton"
                                        onClick={openMainMenu}>
                                        <BackSvg />
                                    </button>
                                    <select
                                        onChange={handleSelectionChange}
                                        className="select selectBox">
                                        {sizeOptions.map((option, index) => {
                                            if (index === 0) {
                                                return (
                                                    <option
                                                        key={`option-${index}`}
                                                        disabled
                                                        selected={!selectedSize}>
                                                        {option}
                                                    </option>
                                                )
                                            }
                                            return (
                                                <option
                                                    key={`option-${index}`}
                                                    value={index - 1}
                                                    className="optionsSize"
                                                    selected={option === selectedSize}>
                                                    {option}
                                                </option>
                                            )
                                        })}
                                    </select>
                                    <button
                                        className="button buttons"
                                        type="submit"
                                        disabled={!selectedSize}>
                                        Begynd at lave puslespil
                                    </button>
                                </form>
                            </>
                        )}
                        {openedMenu === 'editor' && (
                            <PuzzleEditor
                                puzzleBoxes={puzzleBoxes}
                                setPuzzleBoxes={setPuzzleBoxes}
                                handleEditEquation={handleEditEquation}
                                nextToConfirm={nextToConfirm}
                                openCreatePuzzle={openCreatePuzzle}
                                selectedSize={selectedSize}
                                handleSaveUnFinish={handleSaveUnFinish}
                                saving={saving} />
                        )}
                        {openedMenu === 'confirm' && (
                            <form onSubmit={handleSavePuzzle} className="menu">
                                <p className="errorMessage">{errorMessage}</p>
                                <button
                                    type="button"
                                    className="button backButton"
                                    onClick={backButtonToPuzzle}>
                                    <BackSvg />
                                </button>
                                <input
                                    type="text"
                                    name="puzzleName"
                                    placeholder="Navn på puslespil..."
                                    className="input2 input"
                                    onChange={(e) => setPuzzleName(e.target.value)}
                                    value={puzzleName || ''}
                                    minLength={6}
                                    maxLength={18}
                                    required />
                                <div className="uploadBox" onClick={handleFileUpload}>
                                    <input
                                        type="file"
                                        name="upload"
                                        accept="image/jpeg"
                                        className="input2 fileInput"
                                        required
                                        hidden="hidden"
                                        onChange={handleFileUploadChange}
                                        ref={fileInputRef} />

                                    <span className="uploadFileName">
                                        {selectedFile || 'Klik her for at uploade JPEG-billede. Maks 10 MB'}
                                    </span>
                                </div>
                                <textarea
                                    type="text"
                                    name="description"
                                    placeholder="Puslespilsbeskrivelse..."
                                    className="input2 input descriptionInput"
                                    minLength={6}
                                    maxLength={500}
                                    onChange={(e) => setPuzzleDis(e.target.value)}
                                    value={puzzleDis || ''} />
                                <button
                                    className="button buttons loadingSave"
                                    type="submit"
                                    disabled={!selectedFile || saving}>
                                    {saving && <LoadingSvgAnim />}
                                    Gem
                                </button>
                            </form>
                        )}
                        {openedMenu === 'geek' && (
                            <PuzzleGlary
                                backButtonMain={openMainMenu}
                                user_id={null}
                                profile={profile}
                                handleEditPuzzle={handleEditPuzzle} />
                        )}
                        {openedMenu === 'my' && (
                            <PuzzleGlary
                                backButtonMain={openMainMenu}
                                user_id={profile.profile_id}
                                profile={profile}
                                handleEditPuzzle={handleEditPuzzle} />
                        )}
                        <Logo />
                    </main>
                </BodyWithTopBar>
            )}
        </>
    )
}
