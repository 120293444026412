/* eslint-disable @typescript-eslint/no-unused-vars */
import BeenhereIcon from '@material-ui/icons/Beenhere'
import moment from 'moment'
import * as React from 'react'

import { DeleteModal } from '../../components/molecules/DeleteModal'
import { EditAdminModal } from '../../components/organisms/EditAdminModal'
import { ManageEntitiesViewTemplate } from '../../components/organisms/ManageEntitiesViewTemplate'
import { AdminType } from '../../types/types'
import { showMessage } from '../../utils/notifications'
import { createAdminUser, deleteAdmin, getAdmins } from '../../utils/services/admin-user-services'
import { fireWrap } from '../../utils/wrapper'
import { Loading } from '../Loading'

export const AdminProfilesPage: React.FC = () => {
    const [creating, setCreating] = React.useState(false)
    const [items, setItems] = React.useState<AdminType[]>([])
    const [editedItem, setEditedItem] = React.useState<AdminType>()
    const [deletingItems, setDeletingItems] = React.useState<AdminType[]>([])
    const [busy, setBusy] = React.useState(false)

    const handleDelete = async () => {
        setBusy(true)

        await Promise.all(
            deletingItems.map((item) => deleteAdmin(item.email))
        )

        showMessage(`The admin ${deletingItems.map((c) => c.email)} has been deleted.`, 'success')

        const newList = items.filter((item) => !deletingItems.includes(item))
        setItems(newList)
        setDeletingItems([])
        setBusy(false)
    }

    const handleFinished = async (save: boolean, item: { email: string, password: string }, isNew: boolean) => {
        if (save && item) {
            setBusy(true)
            if (isNew) {
                const cmp = await createAdminUser(item)
                if (!cmp) {
                    showMessage(`Failed to create the campaign ${item.email}!`, 'error')
                } else {
                    showMessage(`The campaign ${item.email} has been created.`, 'success')
                    setItems([cmp, ...items])
                }
            } else {
                // todo: create endpoint which set again temporally password
            }

            setBusy(false)
        }

        setEditedItem(undefined)
        setCreating(false)
    }
    React.useEffect(() => {
        fireWrap(async () => {
            setItems(await getAdmins())
        }, {
            operation: 'loading admins',
            stateFunc: setBusy
        })
    }, [])
    if (busy) {
        return (
            <Loading/>
        )
    }
    return (
        <ManageEntitiesViewTemplate
            backgroundColor="#1A2F41"
            icon={<BeenhereIcon style={{ color: '#FFFFFF' }}/>}
            title="Adminprofiler"
            items={items}
            headers={['Email', 'Oprettet']}
            convertItem={(a) => [a.email, moment(a.createdAt).format('DD/MM/YYYY')]}
            canCreate
            canEdit
            canDelete
            onCreate={() => setCreating(true)}
            onDelete={setDeletingItems}
            onEdit={setEditedItem}>
            
            <DeleteModal
                onConfirmAction={handleDelete}
                onCancelAction={() => setDeletingItems([])}
                confirmActionButtonText="Ja, helt sikker"
                cancelActionButtonText="Nej"
                headerText="Er du sikker på at du vil slette denne adminprofil?"
                isOpen={deletingItems.length > 0}
                onClose={() => setDeletingItems([])}/>
                
            <EditAdminModal
                header={editedItem ? 'Rediger Admin Profiler' : 'Opret Admin Profiler'}
                isOpen={!!editedItem || creating}
                isNew={creating}
                email={editedItem?.email}
                onFinish={handleFinished}/>
        </ManageEntitiesViewTemplate>
    ) 
}
