/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from '@material-ui/core'
import { VerifiedUser } from '@material-ui/icons'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { Auth, Hub } from 'aws-amplify'
import * as React from 'react'
import { Redirect } from 'react-router-dom'

import logo from '../../assets/LogoGeekster.png'
import { BackgroundWithImage } from '../../components/atoms/BackgroundWithImage'
import { getModalStyle } from '../../components/molecules/EditModalTemplate'
import { TextInputTemplate } from '../../components/molecules/TextInputTemplate'
import { buttonStyles } from '../../styles/ButtonsStyles'
import { modalStyles } from '../../styles/ModalStyles'
import { showMessage } from '../../utils/notifications'
import { useCognito } from '../../utils/services/cognito-services'

const LoginAuthView: React.FC<{ onAuth(completed: boolean, user: any): void }> = ({ onAuth }) => {
    const btnClasses = buttonStyles()

    const [email, setEmail] = React.useState('amanda+q1@goco.dk')
    const [password, setPassword] = React.useState('ABC456abc$')
    const [doing, setDoing] = React.useState(false)

    const canSubmit = email.length > 6 && password.length > 6

    const handleSubmit = async () => {
        setDoing(true)
        try {
            const user = (await Auth.signIn(email, password))
            if (user) {
                const mustChangePassword = user.challengeName === 'NEW_PASSWORD_REQUIRED'
                if (!mustChangePassword && user instanceof CognitoUser) {
                    const p = user?.getSignInUserSession()?.getAccessToken()?.decodePayload()
                    const gs: string[] = (p && p['cognito:groups']) || []
                    const isAdmin = gs.includes('admin')

                    if (!isAdmin) {
                        await Auth.signOut()
                        throw new Error('User is not an admin.')
                    }
                }
                onAuth(!mustChangePassword, user)
            }
        } catch (e) {
            console.log({ e })
            onAuth(false, undefined)
        }
        setDoing(false)
    }

    return (
        <>
            <TextInputTemplate
                underText="Email addresse for adminprofilen"
                label="Email addresse"
                value={email}
                error={!canSubmit}
                onChange={setEmail}
                type="email" />
            <TextInputTemplate
                underText="Password"
                label="Password"
                value={password}
                error={!canSubmit}
                onChange={setPassword}
                type="password" />
            <Button
                onClick={(e) => {
                    handleSubmit().then()
                    e.preventDefault()
                }}
                variant="contained"
                size="small"
                disabled={!canSubmit || doing}
                className={btnClasses.saveButton}
                startIcon={<VerifiedUser />}>Log ind
            </Button>
        </>
    )
}

const LoginResetView: React.FC<{ user: any, onReset(success: boolean): void }> = ({ user, onReset }) => {
    const btnClasses = buttonStyles()

    const [newPassword, setNewPassword] = React.useState('')
    const [confirmNewPassword, setConfirmNewPassword] = React.useState('')
    const [doing, setDoing] = React.useState(false)

    const handleSubmit = async () => {

        setDoing(true)
        try {
            await Auth.completeNewPassword(user, newPassword)

            onReset(true)
        } catch (e) { console.log({ e }) }

        onReset(false)
        setDoing(false)
    }

    const canSubmit = newPassword.length > 6 && confirmNewPassword === newPassword

    return (
        <>
            <TextInputTemplate
                underText="New Password"
                label="New Password"
                value={newPassword}
                onChange={setNewPassword}
                type="password" />
            <TextInputTemplate
                underText="Password"
                label="Confirm New Password"
                value={confirmNewPassword}
                onChange={setConfirmNewPassword}
                type="password" />
            <Button
                onClick={handleSubmit}
                disabled={!canSubmit || doing}
                variant="contained"
                size="small"
                className={btnClasses.saveButton}
                startIcon={<VerifiedUser />}>Log ind
            </Button>
        </>
    )
}

export const Login: React.FC = () => {
    const { signedIn } = useCognito()
    const classes = modalStyles()

    const [modalStyle] = React.useState(getModalStyle)
    const [stage, setStage] = React.useState<'auth' | 'reset' | 'next'>('auth')
    const [user, setUser] = React.useState<any>()

    if (stage === 'next') {
        return <Redirect to="/" />
    }

    const handleAuth = (completed: boolean, u: any) => {
        if (!u) {
            console.log(u)
            showMessage('User is not allowed to perform action', 'error')
            return
        }
        setUser(u)

        if (completed) {
            Hub.dispatch('auth', { event: 'signIn', data: u })
            setStage('next')
        } else {
            setStage('reset')
        }
    }

    const handleReset = (success: boolean) => {
        if (success) {
            Hub.dispatch('auth', { event: 'signIn', data: user })
            setStage('next')
        } else {
            showMessage('Sorry, and error happened during your request', 'error')
        }
    }

    return (
        <BackgroundWithImage>
            <div
                style={{ background: 'linear-gradient(to right, #b2fefa, #0ed2f7)' }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                <div style={modalStyle} className={classes.login}>
                    <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
                        <img style={{ width: 100, height: 100 }} src={logo} alt="logo" />
                    </div>
                    <h2 style={{
                        textAlign: 'center',
                        fontWeight: 300,
                        fontFamily: 'Encode, sans-serif',
                        color: '#161313'
                    }}>
                        Geekster.dk<br />
                        Administrator Login
                    </h2>
                    {stage === 'auth' && <LoginAuthView onAuth={handleAuth} />}
                    {stage === 'reset' && <LoginResetView user={user} onReset={handleReset} />}
                </div>
            </div>
        </BackgroundWithImage>
    )
}
