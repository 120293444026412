/* eslint-disable no-dupe-keys */
/* eslint-disable react/jsx-filename-extension */
import React from 'react'

export default function Loading() {
    const loadingContStyle = {
        width: '100%',
        height: '100vh',
        height: '100dvh',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }

    return (
        <div style={loadingContStyle}>
            <h1>Indlæser...</h1>
        </div>
    )
}
