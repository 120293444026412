import { ThemeProvider } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import * as React from 'react'

import { primary, primaryContrast } from '../../utils/colors'

const theme = createTheme({
    palette: {
        primary: { main: primary, contrastText: primaryContrast },
        secondary: { main: '#A0A0C7' }
    },
    typography: {
        fontFamily: 'sans-serif'
    }
})

export const Body: React.FC = ({ children }) => (
    <>
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>

    </>
)
