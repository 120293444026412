import { makeStyles } from '@material-ui/core/styles'

import { lightGrey, primaryContrast, white } from '../utils/colors'

export const videoStyles = makeStyles({
    container: {
        padding: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        backgroundColor: white,
        borderBottomColor: '#ddd',
        borderBottomWidth: 2,
        borderBottomStyle: 'solid',
        '&:hover, &:focus': {
            backgroundColor: '#e5f5e5',
        }
    },
    textTitle: {
        fontSize: 16,
        fontWeight: 600,
    },
    paragraph: {
        marginTop: 8,
        marginBottom: 8
    },
    tag: {
        display: 'flex',
        flexDirection: 'row',
        color: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: primaryContrast,
        fontSize: 12,
        fontWeight: 300,
        marginBottom: 5,
        padding: 4,
        borderRadius: 5
    }
})

export const videoLibraryContainer = makeStyles({
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexDirection: 'row',
        paddingRight: 30,
        paddingLeft: 30
    },
    smallText: {
        padding: 0,
        fontWeight: 300,
        color: lightGrey
    },
    formControl: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    switch: {
        color: '#28a745'
    },
    innerContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    questionContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        marginBottom: 25
    },
    plusButton: {
        marginLeft: 10
    },
    arrayTagContainer: {
        padding: 10, border: '1px solid #000', borderRadius: 10, marginBottom: 10
    }
})
