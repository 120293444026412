/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Box, Button, Container, FormControlLabel, FormGroup, Grid, Switch
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import SaveIcon from '@material-ui/icons/Save'
import React, { useEffect } from 'react'

import { buttonStyles } from '../../styles/ButtonsStyles'
import { modalStyles } from '../../styles/ModalStyles'
import { pdfLibraryContainer } from '../../styles/PDFStyles'
import { PDFType, UpdatePDFType } from '../../types/types'
import { possiblePDFCategories, possiblePDFTopics } from '../../utils/filterData'
import { uploadFile } from '../../utils/services/file-services'
import { MultiSelection } from '../molecules/CategoriesSelection'
import { EditModalTemplate, EditModalTemplateProps } from '../molecules/EditModalTemplate'
import { FileUpload } from '../molecules/FileUpload'
import { GradeSelectEdit } from '../molecules/GradeSelectEdit'
import { TagComponent } from '../molecules/TagComponent'
import { TextInputTemplate } from '../molecules/TextInputTemplate'

type Props = EditModalTemplateProps & {
    item?: PDFType
}

export const EditPDFLibraryModal: React.FC<Props> = (props) => {

    const classes = buttonStyles()
    const classesModal = modalStyles()
    const classesPDF = pdfLibraryContainer()

    const [categories, setCategories] = React.useState(props.item?.categories || [])
    const [title, setTitle] = React.useState(props.item?.title || '')
    const [pdfPublic, setPdfPublic] = React.useState(props.item?.isPublic || false)
    const [fact, setFact] = React.useState(props.item?.fact || '')
    const [supplementUri, setSupplementUri] = React.useState(props.item?.supplementUri || [])
    const [description, setDescription] = React.useState(props.item?.description || '')

    const [topics, setTopics] = React.useState(props.item?.topics || [])
    const [grades, setGrades] = React.useState(props.item?.grades || [])

    const [mainFile, setMainFile] = React.useState(props.item?.mainFile)
    const [resultsFile, setResultsFile] = React.useState(props.item?.resultsFile)

    const submit = async () => {

        if (props.onFinish) {
            const obj: UpdatePDFType = {
                title,
                description,
                topics,
                grades,
                categories,
                isPublic: pdfPublic,
                supplementUri,
                fact,
                mainFile,
                resultsFile
            }

            await props.onFinish(true, obj, props.isNew)
        }
    }

    const newProps = {
        ...props,
        isBusy: props.isBusy
    }

    useEffect(() => {
        setDescription(description || '')
        setCategories(categories || [])
        setFact(fact || '')
        setTopics(topics || [])
        setGrades(grades || [])
        setMainFile(mainFile || undefined)
        setResultsFile(resultsFile || undefined)
        setSupplementUri(supplementUri || [])
        setPdfPublic(pdfPublic || false)
    }, [props.isOpen])

    return (
        <EditModalTemplate disableEnforceFocus className={classesModal.FullPage} {...newProps}>
            <Box style={{ width: '100%' }}>
                <Grid container className={classesPDF.container}>
                    <Grid style={{ padding: 10 }} lg={6} item>
                        <MultiSelection
                            label="Kategori"
                            onChange={setCategories}
                            selection={categories}
                            options={possiblePDFCategories} />
                        <TextInputTemplate
                            fullWidth
                            underText="Giv din pdf en titel"
                            label="Titel"
                            value={title}
                            onChange={setTitle}
                            type="default" />
                        <TextInputTemplate
                            fullWidth
                            underText="Giv din pdf en kort beskrivelse"
                            label="Beskrivelse"
                            value={description}
                            onChange={setDescription}
                            type="default" />
                        <FormGroup>
                            <FormControlLabel
                                control={(
                                    <Switch
                                        className={classesPDF.switch}
                                        checked={pdfPublic}
                                        onChange={() => setPdfPublic(!pdfPublic)}
                                        size="medium"
                                        name="checkedA" />
                                )}
                                label="Offentlig pdf" />
                            <small className={classesPDF.smallText}>
                                Når denne er slået til, vil PDF være tilgængelig uden at logge ind
                            </small>
                        </FormGroup>
                    </Grid>
                    <Grid lg={6} item container className={classesPDF.formControl}>
                        <Grid style={{ paddingRight: 10 }} item>
                            <Grid style={{ padding: 10 }} item>
                                <FileUpload
                                    file={mainFile}
                                    buttonText="Uploade"
                                    underText="Her kan du uploade opgaven som pdf-fil"
                                    uploadFunc={uploadFile}
                                    allowedType="document"
                                    onChange={setMainFile} />
                                <GradeSelectEdit
                                    grades={grades}
                                    onChange={setGrades} />
                            </Grid>

                            <Grid item>
                                <TagComponent
                                    underText="Tilføj flere links ved at klikke på godkend"
                                    label="Eksternt link"
                                    onChange={setSupplementUri}
                                    tags={supplementUri} />
                            </Grid>
                            <Grid item>
                                <MultiSelection
                                    label="Emne"
                                    onChange={setTopics}
                                    selection={topics}
                                    options={possiblePDFTopics} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Container className={classesPDF.buttonContainer}>
                    <Button
                        onClick={submit}
                        variant="contained"
                        size="small"
                        disabled={props.isBusy}
                        className={classes.saveButton}
                        startIcon={<SaveIcon />}>Opdater PDF
                    </Button>
                    <Button
                        onClick={() => props.onFinish && props.onFinish(false, undefined, props.isNew)}
                        variant="contained"
                        size="small"
                        disabled={props.isBusy}
                        className={classes.cancelButton}
                        startIcon={<CancelIcon />}>
                        Annuller
                    </Button>
                </Container>
            </Box>
        </EditModalTemplate>
    )
}
