import { API } from 'aws-amplify'

import { SchoolType, UpdateSchoolType } from '../../types/types'

export const createSchool = async (params: UpdateSchoolType): Promise<SchoolType> => {
    return API.post('api', '/schools', {
        body: params
    })
}

export const getSchoolById = async (schoolId: string): Promise<SchoolType> => {
    return API.get('api', `/schools/${schoolId}`, {})
}

export const getSchools = async (): Promise<Partial<SchoolType>[]> => {
    return API.get('api', '/schools', {})
}

export const updateSchool = async (schoolId: string, params: UpdateSchoolType):
    Promise<SchoolType> => {
    return API.put('api', `/schools/${schoolId}`, {
        body: params
    })
}

export const deleteSchool = async (schoolId: string): Promise<void> => {
    await API.del('api', `/schools/${schoolId}`, {})
}
