import { createContext, useContext } from 'react'

export type LoadingContext = (state: boolean) => number
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initial = (state:boolean) => 0

export const AppContext = createContext(initial)

export function useAppContext() {
    return useContext(AppContext)
}
