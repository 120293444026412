import { Box, } from '@material-ui/core'
import React from 'react'

import { videoStyles } from '../../styles/VideoStyles'
import { TextInputTemplate } from './TextInputTemplate'

type Props = {
    underText: string
    label: string
    tags: string[]
    onChange?(tags: string[]): void
}

export const TagComponent: React.FC<Props> = ({
    underText, label, onChange, tags: tgs
}) => {
    const [tags, setTags] = React.useState(tgs)
    const [value, setValue] = React.useState('')

    const classes1 = videoStyles()

    const handleTagInputKeyPress = (key: any, e: React.KeyboardEvent) => {

        if (key.toString() === 'Enter' && value.length > 0) {

            if (!tags.includes(value)) {
                const newTags = [...tags, value]
                setTags(newTags)
                if (onChange) {
                    onChange(newTags)
                }
            }

            setValue('')
            e.preventDefault()
        }
    }

    const removeTag = (t: string) => {
        const filteredTags = tags.filter((tt: string) => tt !== t)
        setTags(filteredTags)
        if (onChange) {
            onChange(filteredTags)
        }
    }
    
    const typedTags = tags.map((t: string) => (
        <Box
            key={t}    
            style={{
                display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', margin: 3
            }}>
            <p className={classes1.tag}>{t}
                <button type="button" onClick={() => removeTag(t)}>x</button>
            </p>
        </Box>
    ))

    return (
        <>
            <TextInputTemplate
                fullWidth
                value={value}
                onChange={setValue}
                onKeyPress={handleTagInputKeyPress}
                underText={underText}
                label={label}
                type="default"/>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                { typedTags }
            </div>
        </>
    )
}
