import { Button, Container } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import SaveIcon from '@material-ui/icons/Save'
import React, { useState } from 'react'

import { buttonStyles } from '../../styles/ButtonsStyles'
import { modalStyles } from '../../styles/ModalStyles'
import { resetTeacherPassword } from '../../utils/services/teacher-services'
import { wrap } from '../../utils/wrapper'
import { EditModalTemplate, EditModalTemplateProps } from '../molecules/EditModalTemplate'
import { TextInputTemplate } from '../molecules/TextInputTemplate'
import { ToggleTemplate } from '../molecules/ToggleTemplate'

type Props = EditModalTemplateProps & {
    teacherId?: string,
    email?: string,
    firstName?: string,
    lastName?: string,
    school?: string,
    isActive?: boolean
}

export const EditProfessorModal: React.FC<Props> = (props) => {

    const classes = buttonStyles()
    const classesModal = modalStyles()
    const [email, setEmail] = React.useState(props.email)
    const [firstName, setFirstName] = React.useState(props.firstName)
    const [lastName, setLastName] = React.useState(props.lastName)
    const [password, setPassword] = React.useState('')
    const [confirmPassword, setConfirmPassword] = React.useState('')
    const [school, setSchool] = React.useState(props.school)
    const [isUserActive, setIsUserActive] = React.useState(props.isActive)
    const [busy, setBusy] = useState<boolean>(false)

    React.useEffect(() => {
        setEmail(props.email)
        setPassword('')
        setFirstName('')
        setSchool('')
        setLastName('')
        setConfirmPassword('')
    }, [props.isOpen])

    const submit = async () => {
        if (email?.length === 0) {
            // todo: show some error
            return
        }

        if (props.onFinish) {
            const obj = {
                email,
                firstName,
                lastName,
                school,
                isUserActive

            }

            await props.onFinish(true, obj, props.isNew)
        }
    }

    const resetPassword = async () => {
        await wrap(async () => {
            await resetTeacherPassword(props.teacherId!!)
        }, {
            operation: 'Resetting Password',
            stateFunc: setBusy
        })
    }

    const newProps = {
        ...props,
        isBusy: props.isBusy || busy,
    }

    return (
        <EditModalTemplate className={classesModal.paperFullWidth} {...newProps}>
            <TextInputTemplate
                underText="Email addresse for lærerprofilen"
                label="Email"
                value={email}
                onChange={setEmail}
                type="email"/>
            <TextInputTemplate
                underText="Password for lærerprofilen"
                disabled={!props.isNew}
                label="Password"
                value={password}
                onChange={setPassword}
                type="password"/>
            <TextInputTemplate
                underText="Indtast password igen for at bekræfte"
                label="Bekræft password"
                disabled={!props.isNew}
                value={confirmPassword}
                onChange={setConfirmPassword}
                type="password"/>
            <TextInputTemplate
                underText="Skole for lærerprofilen"
                label="Skole"
                value={school}
                onChange={setSchool}
                type="default"/>
            <TextInputTemplate
                underText="Fornavnet på brugeren af lærerprofilenen"
                label="Fornavn"
                value={firstName}
                onChange={setFirstName}
                type="default"/>
            <TextInputTemplate
                underText="Efternavnet på brugeren af lærerprofilenen"
                label="Efternavn"
                value={lastName}
                onChange={setLastName}
                type="default"/>
            <ToggleTemplate  
                label="Aktiv"
                value={isUserActive}
                onChange={setIsUserActive}
                underText="Brugeren kan ikke logge ind, hvis denne er slået fra"/>

            <Container className={classes.buttonsWrapper}>
                <Button
                    onClick={submit}
                    variant="contained"
                    size="small"
                    disabled={props.isBusy}
                    className={classes.saveButton}
                    startIcon={<SaveIcon />}>Opdater
                </Button>
                <Button
                    onClick={resetPassword}
                    variant="contained"
                    size="small"
                    disabled={props.isNew || props.isBusy}
                    className={classes.saveButton}
                    startIcon={<RotateLeftIcon />}>Nulstil password
                </Button>
                <Button
                    onClick={() => props.onFinish && props.onFinish(false, undefined, props.isNew)}
                    variant="contained"
                    size="small"
                    disabled={props.isBusy}
                    className={classes.cancelButton}
                    startIcon={<CancelIcon/>}>
                    Annuller
                </Button>
            </Container>

        </EditModalTemplate>
    )
}
