import { API } from 'aws-amplify'

import { CommunityQuizType, UpdateCommunityQuizType } from '../../types/types'

export const getCommunityQuizzes = async (): Promise<CommunityQuizType[]> => {
    return API.get('api', '/quizzes/community/public', {})
}
export const getCommunityQuizById = async (quizId: string): Promise<CommunityQuizType> => {
    return API.get('api', `/quizzes/community/${quizId}`, {})
}
export const updateCommunityQuizStatus = async (quizId: string, params: UpdateCommunityQuizType):
    Promise<CommunityQuizType> => {
    return API.put('api', `/quizzes/community/status/${quizId}`, {
        body: params
    })
}
