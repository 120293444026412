/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Box, Button, Container, FormControlLabel, FormGroup, Grid, Switch
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import PauseIcon from '@material-ui/icons/Pause'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import SaveIcon from '@material-ui/icons/Save'
import { Howl } from 'howler'
import React, { useEffect } from 'react'

import { buttonStyles } from '../../styles/ButtonsStyles'
import { modalStyles } from '../../styles/ModalStyles'
import { videoLibraryContainer } from '../../styles/VideoStyles'
import { FileType, SoundType, UpdateSoundType } from '../../types/types'
import { uploadFile } from '../../utils/services/file-services'
import { EditModalTemplate, EditModalTemplateProps } from '../molecules/EditModalTemplate'
import { FileUpload } from '../molecules/FileUpload'
import { TextInputTemplate } from '../molecules/TextInputTemplate'

type Props = EditModalTemplateProps & {
    item?: SoundType
}

export const EditSoundLibraryModal: React.FC<Props> = (props) => {

    const classes = buttonStyles()
    const classesModal = modalStyles()
    const classesVideo = videoLibraryContainer()
    const [title, setTitle] = React.useState(props.item?.title || '') // eslint-disable-next-line spaced-comment
    const [soundFile, setSoundFile] = React.useState<FileType|null>( //@ts-ignore
        props.item?.soundFile && Object.keys(props.item?.soundFile).length 
            ? props.item?.soundFile : null as unknown as FileType
    )
    const [isCorrectFeedback, setIsCorrectFeedback] = React.useState(props.item?.isCorrectFeedback || false)
    const [playingSound, setPlayingSound] = React.useState(false)
    const [sound, setSound] = React.useState<Howl>(null as unknown as Howl)
    useEffect(() => {
        if (soundFile) {
            setSound(new Howl({
                src: String(soundFile.uri),
                html5: true,
                onend() {
                    setPlayingSound(false)
                },
            }))
        }
    }, [soundFile])

    const submit = async () => {
        if (props.onFinish) {
            const obj: UpdateSoundType = {
                title,
                soundFile: { uri: String(soundFile?.uri), key: String(soundFile?.key), name: String(soundFile?.name) },
                isCorrectFeedback
            }

            await props.onFinish(true, obj, props.isNew)
        }
    }

    const newProps = {
        ...props,
        isBusy: props.isBusy
    }

    useEffect(() => {  
    }, [props.isOpen])

    return (
        <EditModalTemplate disableEnforceFocus className={classesModal.FullPage} {...newProps}>
            <Box style={{ width: '100%' }}>
                <Grid container className={classesVideo.container}>
                    <Grid style={{ padding: 10 }} lg={6} item>
                        <TextInputTemplate
                            fullWidth
                            underText="Giv din sound en Navn"
                            label="Navn"
                            value={title}
                            onChange={setTitle}
                            type="default"/>
                    </Grid>
                    <Grid lg={6} item container className={classesVideo.formControl}>
                        <Grid style={{ paddingRight: 10 }} item>
                            <Grid style={{ padding: 10 }} item>
                                <FileUpload
                                    file={soundFile}
                                    buttonText="Uploade"
                                    underText="Her kan du uploade som mp3-fil"
                                    uploadFunc={uploadFile}
                                    allowedType="sound" 
                                    onChange={setSoundFile}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid lg={6} item container className={classesVideo.formControl}>
                        <FormGroup>
                            <FormControlLabel
                                control={(
                                    <Switch
                                        className={classesVideo.switch}
                                        checked={isCorrectFeedback}
                                        onChange={() => setIsCorrectFeedback(!isCorrectFeedback)}
                                        size="medium"
                                        name="checkedA" />
                                )}
                                label="Er korrekt feedback?"/>
                            <small className={classesVideo.smallText}>
                                Er dette meningen at repræsentere en lyd for korrekt/forkert feedback?
                            </small>
                        </FormGroup>
                    </Grid>
                    <Grid lg={6} item container className={classesVideo.formControl}>

                        {sound ? (
                            <Button onClick={() => {
                                if (sound) {
                                    if (!playingSound) {
                                        sound.play()
                                    } else {
                                        sound.stop()
                                    }
                                    setPlayingSound(!playingSound)
                                }

                            }}>{playingSound ? <PauseIcon/> : <PlayArrowIcon/> }
                            </Button>
                        ) : null }
                            
                    </Grid>
                </Grid>
                <Container className={classesVideo.buttonContainer}>
                    <Button
                        onClick={submit}
                        variant="contained"
                        size="small"
                        disabled={props.isBusy || !soundFile}
                        className={classes.saveButton}
                        startIcon={<SaveIcon />}>Opdater Lyd
                    </Button>
                    <Button
                        onClick={() => props.onFinish && props.onFinish(false, undefined, props.isNew)}
                        variant="contained"
                        size="small"
                        disabled={props.isBusy}
                        className={classes.cancelButton}
                        startIcon={<CancelIcon/>}>
                        Annuller
                    </Button>
                </Container>
            </Box>
        </EditModalTemplate>
    )
}
