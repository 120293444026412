import { API } from 'aws-amplify'

import { CampaignType, UpdateCampaignType } from '../../types/types'

export const createCampaign = async (params: UpdateCampaignType): Promise<CampaignType> => {
    return API.post('api', '/campaign', {
        body: params
    })
}

export const getCampaigns = async (): Promise<CampaignType[]> => {
    return API.get('api', '/campaigns', {})
}

export const updateCampaign = async (campaignId: string, params: UpdateCampaignType):
    Promise<CampaignType> => {
    return API.put('api', `/campaigns/${campaignId}`, {
        body: params
    })
}

export const deleteCampaign = async (campaignId: string): Promise<void> => {
    await API.del('api', `/campaigns/${campaignId}`, {})
}
