import {
    Button, Container, FormControlLabel, FormGroup, Switch 
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import SaveIcon from '@material-ui/icons/Save'
import React from 'react'

import { buttonStyles } from '../../styles/ButtonsStyles'
import { modalStyles } from '../../styles/ModalStyles'
import { videoLibraryContainer } from '../../styles/VideoStyles'
import { FileType, UpdateCampaignType } from '../../types/types'
import { isValidHttpUrl } from '../../utils/helper'
import { uploadFile } from '../../utils/services/file-services'
import { EditModalTemplate, EditModalTemplateProps } from '../molecules/EditModalTemplate'
import { FileUpload } from '../molecules/FileUpload'
import { TextInputTemplate } from '../molecules/TextInputTemplate'

type Props = EditModalTemplateProps & {
    description?: string,
    offerLink?: string,
    image?: FileType,
    published?: boolean
}

export const EditACampaignModal: React.FC<Props> = (props) => {
    const classes = buttonStyles()
    const [description, setDescription] = React.useState(props.description || '')
    const [offerLink, setOfferLink] = React.useState(props.offerLink || '')
    const [image, setImage] = React.useState(props.image)
    const [published, setPublished] = React.useState(props.published || false)

    const classesModal = modalStyles()
    const classesVideo = videoLibraryContainer()

    const codeError = !description || description.length < 2
    const linkError = !isValidHttpUrl(offerLink)
    const error = codeError || linkError

    const submit = async () => {
        if (error) return

        if (props.onFinish) {
            const obj: UpdateCampaignType = {
                link: offerLink,
                description,
                picture: image,
                published
            }

            await props.onFinish(true, obj, props.isNew)
        }
    }

    return (
        <EditModalTemplate className={classesModal.paper} {...props}>
            <TextInputTemplate
                error={codeError}
                underText="Beskrivelse af tilbuddet"
                label="Beskrivelse"
                value={description}
                onChange={setDescription}
                type="default"/>
            <TextInputTemplate
                error={linkError}
                underText="Link til tilbuddet"
                label="Link"
                value={offerLink}
                onChange={setOfferLink}
                type="default" />
            <FormGroup>
                <FormControlLabel
                    control={(
                        <Switch
                            className={classesVideo.switch}
                            checked={published}
                            onChange={() => setPublished(!published)}
                            size="medium"
                            name="checkedA" />
                    )}
                    label="Tilbud offentliggjort"/>
                <small className={classesVideo.smallText}>
                    Når dette er slået til, vil tilbuddet være synligt for lærerne
                </small>
            </FormGroup>
            <FileUpload
                file={image}
                buttonText="Upload"
                underText="Du kan her uploade et billede af tilbuddet"
                uploadFunc={uploadFile}
                allowedType="image"
                onChange={setImage}/>
            
            <Container className={classes.buttonsWrapper}>
                <Button
                    onClick={submit}
                    variant="contained"
                    size="small"
                    disabled={props.isBusy || error}
                    className={classes.saveButton}
                    startIcon={<SaveIcon />}>Opdater
                </Button>
                <Button
                    onClick={() => props.onFinish && props.onFinish(false, undefined, props.isNew)}
                    variant="contained"
                    size="small"
                    disabled={props.isBusy}
                    className={classes.cancelButton}
                    startIcon={<CancelIcon/>}>
                    Annuller
                </Button>
            </Container>
        </EditModalTemplate>
    )
}
