import { Container, Icon, Typography } from '@material-ui/core'
import * as React from 'react'

interface Props {
    title: string;
    background: string;
    icon: JSX.Element;
}

export const TitleContainer: React.FC<Props> = ({ title, background, icon }) => {
    const WrappedIcon = (props: any) => <Icon {...props} />
    WrappedIcon.muiName = 'Icon'

    return (
        <Container style={{
            maxWidth: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: background,
            padding: 15
        }}>
            {icon}
            <Typography style={{ color: '#FFFFFF', paddingLeft: 10 }} variant="h5"> {title}</Typography>
        </Container>
    )
}
