import { showMessage } from './notifications'

interface WrapOpts {
    stateFunc?(busy: boolean): void
    operation?: string
    showSuccess?: boolean
}

export async function wrap<T>(f :() => Promise<T>, opts?: WrapOpts) {
    try {
        if (opts?.stateFunc) {
            opts.stateFunc(true)
        }

        const r = await f()

        if (opts?.showSuccess) {
            showMessage(`Successfully completed ${opts?.operation || 'unknown'} operation.`, 'success')
        }
        
        return r
    } catch (error) {
        showMessage(`There was an error while executing ${opts?.operation || 'unknown'} operation!`, 'error')

        throw error
    } finally {
        if (opts?.stateFunc) {
            opts.stateFunc(false)
        }
    }
}

export function fireWrap<T>(f :() => Promise<T>, opts?: WrapOpts): void {
    wrap(f, opts).then()
}
