import {
    Button, Container, Modal, Typography 
} from '@material-ui/core'
import React from 'react'

import { buttonStyles } from '../../styles/ButtonsStyles'
import { modalStyles } from '../../styles/ModalStyles'

type Props = {
    onCancelAction?: () => void
    onConfirmAction?: () => void
    headerText: string
    isOpen: boolean
    onClose?: () => void
    confirmActionButtonText: string
    cancelActionButtonText: string
}

function getModalStyle() {
    const top = 50
    const left = 50
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

export const DeleteModal: React.FC<Props> = ({
    confirmActionButtonText,
    cancelActionButtonText,
    isOpen,
    headerText,
    onClose,
    onCancelAction,
    onConfirmAction
}) => {
    const classes = modalStyles()
    const btn = buttonStyles()
    const [modalStyle] = React.useState(getModalStyle)

    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={isOpen}
            onClose={onClose}>
            <div style={modalStyle} className={classes.paperLogout}>
                <Typography
                    style={{ textAlign: 'center', fontSize: 18, padding: 20 }}
                    variant="h6">{headerText}
                </Typography>
                <Container className={btn.buttonsWrapper}>
                    <Button
                        onClick={onConfirmAction}
                        variant="contained"
                        size="small"
                        className={btn.saveButton}>
                        {confirmActionButtonText}
                    </Button>
                    <Button
                        onClick={onCancelAction}
                        variant="contained"
                        size="small"
                        className={btn.cancelButton}>
                        {cancelActionButtonText}
                    </Button>
                </Container>
            </div>
        </Modal>
    )
}
