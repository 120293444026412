/* eslint-disable @typescript-eslint/no-unused-vars */
import PeopleIcon from '@material-ui/icons/People'
import moment from 'moment'
import * as React from 'react'

import { DeleteModal } from '../../components/molecules/DeleteModal'
import { EditProfessorModal } from '../../components/organisms/EditProfessorModal'
import { ManageEntitiesViewTemplate } from '../../components/organisms/ManageEntitiesViewTemplate'
import { TeacherType } from '../../types/types'
import { showMessage } from '../../utils/notifications'
import { createTeacherUser, deleteTeacher, getTeachers } from '../../utils/services/teacher-services'
import { Loading } from '../Loading'

const filterProfessors = (filter: string, item: TeacherType) => {
    return item.firstName?.toLowerCase().includes(filter.toLowerCase())!!
        || item.lastName?.toLowerCase().includes(filter.toLowerCase())!!
        || item.email?.toLowerCase().includes(filter.toLowerCase())!!
        || item.school?.toLowerCase().includes(filter.toLowerCase())!!
        || item.coupon?.toLowerCase().includes(filter.toLowerCase())!!
}
export const ProfessorProfilesPage: React.FC = () => {

    const [creating, setCreating] = React.useState(false)
    const [items, setItems] = React.useState<TeacherType[]>([])
    const [editedItem, setEditedItem] = React.useState<TeacherType>()
    const [deletingItems, setDeletingItems] = React.useState<TeacherType[]>([])

    const [busy, setBusy] = React.useState(false)

    const handleDeleteProfessors = async () => {
        setBusy(true)

        await Promise.all(
            deletingItems.map((item) => deleteTeacher(item.id))
        )

        showMessage(`The school ${deletingItems.map((c) => c.email)} has been deleted.`, 'success')

        const newList = items.filter((item) => !deletingItems.includes(item))

        setItems(newList)
        setDeletingItems([])
        setBusy(false)
    }

    const handleClose = async (save: boolean, item: { email: string, password: string }, isNew: boolean) => {

        if (save && item) {
            if (isNew) {
                createTeacherUser(item).then((ok) => {
                    if (!ok) {
                        showMessage(`Failed to create teacher ${item.email}!`, 'error')
                    } else {
                        showMessage(`The teacher  ${item.email} has been created.`, 'success')
                    }
                })
            } else {
                // todo: update in db .....(when api ready)
            }
        }
        setEditedItem(undefined)
        setCreating(false)
    }

    React.useEffect(() => {
        (async () => {
            setBusy(true)

            try {
                const result = await getTeachers()
                setItems(result || [])
            } catch (error) {
                showMessage('Error on loading teachers', 'error')
            }

            setBusy(false)
        })()
    }, [])

    if (busy) {
        return (
            <Loading/>
        )
    }
    return (
        <ManageEntitiesViewTemplate
            searchComponent
            backgroundColor="#FF6429"
            icon={<PeopleIcon style={{ color: '#FFFFFF' }}/>}
            title="Lærerprofiler"
            items={items}
            filterItem={filterProfessors}
            headers={['Email', 'Skole', 'Fornavn', 'Efternavn', 'Oprettet', 'Udløber', 'Pris', 'Kode', 'Aktiv']}
            convertItem={(p) => [p.email, p.school, p.firstName, p.lastName, moment(p.createdAt).format('DD/MM/YYYY'),
                p.subscriptionPricePlan,
                p.award,
                p.coupon,
                p.isActive === true ? 'Ja' : 'Nej']}
            canCreate
            canEdit
            canDelete
            onCreate={() => setCreating(true)}
            onDelete={setDeletingItems}
            onEdit={setEditedItem}>
            <DeleteModal
                onConfirmAction={handleDeleteProfessors}
                onCancelAction={() => setDeletingItems([])}
                confirmActionButtonText="Ja, helt sikker"
                cancelActionButtonText="Nej"
                headerText="Er du sikker på at du vil slette denne lærerprofil?"
                isOpen={deletingItems.length > 0}
                onClose={() => setDeletingItems([])}/>
            <EditProfessorModal
                isOpen={!!editedItem || creating}
                isNew={creating}
                teacherId={editedItem?.id}
                email={editedItem?.email}
                firstName={editedItem?.firstName}
                lastName={editedItem?.lastName}
                school={editedItem?.school}
                isActive={editedItem?.isActive}
                header="Rediger Lærerprofil"
                onFinish={handleClose}/>
        </ManageEntitiesViewTemplate>
    )
}
