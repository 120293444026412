import { Container } from '@material-ui/core'
import * as React from 'react'

import { HomeworkStatisticsView } from '../../components/molecules/HomeworkStatisticsView'
import { TeacherStatisticsView } from '../../components/molecules/TeacherStatisticsView'
import { ToggleTemplate } from '../../components/molecules/ToggleTemplate'
import { VideoStatisticsView } from '../../components/molecules/VideoStatisticsView'
import { BodyWithTopBar } from '../../components/wrappers/BodyWithTopBar'
import { AdminStatistics } from '../../types/types'
import { getAdminStatistics } from '../../utils/services/report-services'
import { fireWrap } from '../../utils/wrapper'
import { Loading } from '../Loading'

type Props = {
}

export const HomePage: React.FC<Props> = () => {
    const [busy, setBusy] = React.useState(false)
    const [adminStatistics, setAdminStatistics] = React.useState<AdminStatistics>()

    React.useEffect(() => {
        fireWrap(async () => {
            await setAdminStatistics(
                await getAdminStatistics()
            )
        }, {
            operation: 'Getting admin stats',
            stateFunc: setBusy
        })
    }, [])

    if (busy || !adminStatistics) {
        return (
            <Loading/>
        )
    }

    return (

        <BodyWithTopBar>
            <Container style={{ padding: '2em' }}>
                <div style={{ marginBottom: 20 }}>
                    <ToggleTemplate label="Offentlig på Geekster.dk"/>
                </div>
                <Container>
                    <div style={{
                        display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginBottom: 40
                    }}>
                        <VideoStatisticsView
                            privateVideos={adminStatistics.privateVideos}
                            publicVideos={adminStatistics.publicVideos}/>
                        <HomeworkStatisticsView
                            totalHomeworkCount={adminStatistics.totalHomeworkCount}
                            homeworksCreatedByDate={adminStatistics.homeworksCreatedByDate}/>
                    </div>
                    <TeacherStatisticsView
                        totalTeacherCount={adminStatistics.totalTeacherCount}
                        teacherRegistrationsByDate={adminStatistics.teacherRegistrationsByDate}/>
                </Container>
            </Container>
        </BodyWithTopBar>
    ) 
}
