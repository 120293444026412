/* eslint-disable @typescript-eslint/no-unused-vars */
import 'react-quill/dist/quill.snow.css'
import 'katex/dist/katex.min.css'

import {
    Box, Button, Container, Grid, Typography,
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import SaveIcon from '@material-ui/icons/Save'
import parse from 'html-react-parser'
import katex from 'katex'
import React, { useEffect } from 'react'
import ReactQuill from 'react-quill'

import { buttonStyles } from '../../styles/ButtonsStyles'
import { modalStyles } from '../../styles/ModalStyles'
import { videoLibraryContainer } from '../../styles/VideoStyles'
import { Question } from '../../types/types'
import { uploadFile } from '../../utils/services/file-services'
import { validateVimeoVideo } from '../../utils/services/video-library-services'
import { EditModalTemplate, EditModalTemplateProps } from '../molecules/EditModalTemplate'
import { FileUpload } from '../molecules/FileUpload'
import { MultipleAnswers } from '../molecules/MultipleAnswers'
import { TagComponent } from '../molecules/TagComponent'
import { TextInputTemplate } from '../molecules/TextInputTemplate'
import { VimeoVideoInput } from '../molecules/VimeoVideoInput'

window.katex = katex

type Props = EditModalTemplateProps & {
    item?: Question,
    viewOnly?: boolean
}

export const QuizQuestionAndAnswersModal: React.FC<Props> = (props) => {

    const classes = buttonStyles()
    const classesModal = modalStyles()
    const classesVideo = videoLibraryContainer()

    const [title, setTitle] = React.useState(props.item?.title || '')
    const [explanation, setExplanation] = React.useState(props.item?.explanation || '')
    const [vimeoVideoId, setVimeoVideoId] = React.useState(props.item?.vimeoVideoId || '')
    const [responses, setResponses] = React.useState(props.item?.responses || [])
    const [supplementLinks, setSupplementLinks] = React.useState(props.item?.supplementLinks || [])
    const [pictureFile, setPictureFile] = React.useState(props.item?.picture)
    const [vimeoVideoValidating, setVimeoVideoValidating] = React.useState(false)

    const canSubmit = (
        title.length > 4 && explanation.length > 4 && !vimeoVideoValidating
    )
    
    const submit = async () => {
        if (!canSubmit) {
            return
        }

        if (props.onFinish) {
            const vimeoInfo = await validateVimeoVideo(vimeoVideoId)
            const obj: Question = {
                title,
                explanation,
                vimeoVideoId,
                responses,
                supplementLinks,
                picture: pictureFile,
                thumbnailUri: vimeoInfo?.thumbnailUri
            }

            await props.onFinish(true, obj, props.isNew)
        }
    }

    const newProps = {
        ...props,
        isBusy: props.isBusy || vimeoVideoValidating,
    }

    useEffect(() => {
        setTitle(props.item?.title || '')
        setExplanation(props.item?.explanation || '')
        setPictureFile(props.item?.picture || undefined)
        setResponses(props.item?.responses || [])
        setSupplementLinks(props.item?.supplementLinks || [])
        setVimeoVideoId(props.item?.vimeoVideoId || '')
    }, [props.isOpen])

    return (
        <EditModalTemplate className={classesModal.FullPage} {...newProps}>
            <Box style={{ width: '100%' }}>
                <Grid container className={classesVideo.container}>
                    <Grid lg={12} item>
                        <TextInputTemplate
                            fullWidth
                            disabled={props.viewOnly}
                            underText={props.viewOnly ? '' : 'Indtast spørgsmålstitel'}
                            label="Spørgsmålets titel"
                            value={title}
                            onChange={setTitle}
                            type="default"/>
                        <TagComponent
                            underText="Tilføj flere links ved at klikke på godkend"
                            label="Eksternt link"
                            onChange={setSupplementLinks}
                            tags={supplementLinks}/>
                        <Grid lg={12} item>
                            {props.viewOnly ? (
                                <Typography><b>Forklaring:</b> {parse(explanation)}</Typography>
                            )
                                : (
                                    <>
                                        <ReactQuill
                                            style={{ background: '#FFF', marginBottom: 10 }}
                                            theme="snow"
                                            value={explanation}
                                            onChange={setExplanation}/>
                                        <small>Indtast en forklaring her</small>
                                    </>
                                )}
                        </Grid>
                    </Grid>

                    <Grid lg={12} item>

                        <MultipleAnswers 
                            viewOnly={props.viewOnly}
                            underText="Indtast svar på spørgsmålet"
                            label="Svar" 
                            answers={responses} 
                            onChange={setResponses}/>

                        {props.viewOnly 
                            ? (props.item && props.item.picture && <img src={props.item.picture.uri} alt="question" />)
                            : (
                                <FileUpload
                                    file={pictureFile}
                                    buttonText="Vælg fil"
                                    allowedType="image"
                                    underText="Du kan tilføje et billede i formatet .png, .jpeg, .jpg eller .gif"
                                    uploadFunc={uploadFile}
                                    onChange={setPictureFile}/>
                            )}

                        <VimeoVideoInput
                            isDisabled={props.viewOnly}
                            vimeoVideoId={props.item?.vimeoVideoId}
                            onBusy={setVimeoVideoValidating}
                            onChange={(v) => v && setVimeoVideoId(v.id)}/>
                    </Grid>
                </Grid>

                <Container className={classesVideo.buttonContainer}>
                    {!props.viewOnly && (
                        <Button
                            onClick={submit}
                            variant="contained"
                            size="small"
                            disabled={props.isBusy || !canSubmit}
                            className={classes.saveButton}
                            startIcon={<SaveIcon />}>Opdater spørgsmål
                        </Button>
                    )}
                    <Button
                        onClick={() => props.onFinish && props.onFinish(false, undefined, props.isNew)}
                        variant="contained"
                        size="small"
                        disabled={props.isBusy}
                        className={classes.cancelButton}
                        startIcon={<CancelIcon/>}>
                        {props.viewOnly ? 'Luk' : 'Annuller'}
                    </Button>
                </Container>
            </Box>
        </EditModalTemplate>
    )
}
