/* eslint-disable @typescript-eslint/no-unused-vars */
import 'react-quill/dist/quill.snow.css'

import { Button, Container } from '@material-ui/core'
import PublicIcon from '@material-ui/icons/Public'
import SaveIcon from '@material-ui/icons/Save'
import * as React from 'react'
import ReactQuill from 'react-quill'

import { TitleContainer } from '../../components/molecules/TitleContainer'
import { ToggleTemplate } from '../../components/molecules/ToggleTemplate'
import { VimeoVideoInput } from '../../components/molecules/VimeoVideoInput'
import { BodyWithTopBar } from '../../components/wrappers/BodyWithTopBar'
import { buttonStyles } from '../../styles/ButtonsStyles'
import { PresentationType } from '../../types/types'
import { showMessage } from '../../utils/notifications'
import { getPresentation, savePresentation, updatePresentation } from '../../utils/services/presentation-service'
import { Loading } from '../Loading'

export const PresentationPage: React.FC = () => {
    const [content, setContent] = React.useState('')
    const [presentationPublic, setPresentationPublic] = React.useState<boolean>(true)
    const [presentationId, setPresentationId] = React.useState()
    const [busy, setBusy] = React.useState(false)
    const [vimeoVideoId, setVimeoVideoId] = React.useState('')
    const [vimeoVideoValidating, setVimeoVideoValidating] = React.useState(false)

    const classes = buttonStyles()
    const onChange = (newContent: any) => {
        setContent(newContent)
    }

    const save = async () => {
        const item: PresentationType = {
            id: presentationId,
            content,
            public: presentationPublic,
            vimeoVideoId
        }

        if (item) {
            await updatePresentation(item)
        } else {
            await savePresentation(item)
        }
        showMessage('The presentation message has been updated.', 'success')
    }

    React.useEffect(() => {
        (async () => {
            setBusy(true)
            try {
                const result = await getPresentation() as PresentationType
                setContent(result.content || '')
                setPresentationPublic(!!result?.public)
                setPresentationId(result.id)
                setVimeoVideoId(result.vimeoVideoId || '')
            } catch (error) {
                showMessage('Error on loading presentation', 'error')
            }

            setBusy(false)
        })()
    }, [])

    if (busy) {
        return (
            <Loading/>
        )
    }

    return (
        <BodyWithTopBar>
            <TitleContainer
                icon={<PublicIcon style={{ color: '#FFFFFF' }} />}
                background="#5C90B2"
                title="Præsentation" />
            <Container style={{ padding: '4em' }}>
                <div style={{ marginBottom: 20 }}>
                    <ToggleTemplate
                        label="Offentlig på Geekster.dk"
                        value={presentationPublic}
                        onChange={() => setPresentationPublic(presentationPublic)}/>
                </div>
                <>
                    <VimeoVideoInput
                        vimeoVideoId={vimeoVideoId}
                        onBusy={setVimeoVideoValidating}
                        onChange={(v) => v && setVimeoVideoId(v.id)}/>
                    <ReactQuill
                        style={{ background: '#FFF', marginBottom: 10 }}
                        theme="snow"
                        value={content}
                        onChange={onChange}/>
                    <Container>
                        <Button
                            onClick={save}
                            variant="contained"
                            size="small"
                            className={classes.saveButton}
                            startIcon={<SaveIcon />}>Opdater
                        </Button>
                    </Container>
                </>
            </Container>
        </BodyWithTopBar>
    )
}
