import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import * as React from 'react'

import { TablesStyles } from '../../styles/TablesStyles'

type SelectionMode = 'none' | 'single' | 'multi';

type Props = {
    headers: string[];
    content: any[][];
    arialLabel: string;
    selectionMode?: SelectionMode;
    onSelectionChange?: (selection: number[]) => void;
}

export const SimpleTableWithPagination: React.FC<Props> = ({
    headers, arialLabel, content, onSelectionChange, selectionMode,
}) => {

    const classes = TablesStyles()
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(5)
    const [selectedIndexes, setSelectedIndexes] = React.useState<number[]>([])

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleRowClick = (index: number) => {
        let newSelection = selectedIndexes

        if (selectionMode === 'single') {
            if (selectedIndexes.includes(index)) {
                newSelection = []
            } else {
                newSelection = [index]
            }

        } else if (selectionMode === 'multi') {
            if (selectedIndexes.includes(index)) {
                newSelection = selectedIndexes.filter((i) => i !== index)
            } else {
                newSelection = [...selectedIndexes, index]
            }
        }
        setSelectedIndexes(newSelection)

        if (onSelectionChange) {
            onSelectionChange(newSelection)
        }
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, content.length - page * rowsPerPage)
    const globalIndex = (index: number) => page * rowsPerPage + index

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label={arialLabel}>
                <TableHead>
                    <TableRow>
                        {headers.map((header, colIndex) => {
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <TableCell key={colIndex} className={classes.cell}>{header}</TableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {content.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row: any[], index: number) => (
                            <TableRow
                                onClick={() => handleRowClick(globalIndex(index))}
                                className={
                                    selectedIndexes.includes(globalIndex(index))
                                        ? classes.selectedCellUnit : classes.cellUnit
                                }
                                key={globalIndex(index)}>
                                {row.map((c, colIndex) => {
                                    return (
                                        <TableCell
                                            key={colIndex}
                                            component="th"
                                            scope="row">{c}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        ))}

                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={content.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}/>
           
        </TableContainer>
    )
}
