import { makeStyles } from '@material-ui/core/styles'

export const modalStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        overflow: 'scroll',
        justifyContent: 'center',
        outline: 0
    },
    backDrop: {
        background: 'rgba(140,34,34,0.2)',
    },
    paper: {
        position: 'absolute',
        outline: 0,
        width: 700,
        overflow: 'scroll',
        backgroundColor: '#F1ECE8',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    paperFullWidth: {
        position: 'absolute',
        outline: 0,
        width: '100%',
        height: '100%',
        overflow: 'scroll',
        backgroundColor: '#F1ECE8',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),

    },
    paperLogout: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        outline: 0,
        width: 500,
        overflow: 'hidden',
        height: 200,
        backgroundColor: '#F1ECE8',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 8
    },
    login: {
        borderRadius: 10,
        position: 'absolute',
        outline: 0,
        fontFamily: 'Encode, sans-serif',
        width: 450,
        backgroundColor: '#ffffff',
        overflow: 'hidden',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    paperBigger: {
        position: 'absolute',
        outline: 0,
        width: 1200,
        overflow: 'scroll',
        backgroundColor: '#F1ECE8',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    FullPage: {
        flexGrow: 1,
        width: '100%',
        height: '100%',
        overflow: 'scroll',
        position: 'absolute',
        outline: 0,
        backgroundColor: '#F1ECE8',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}))
