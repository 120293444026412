import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import * as React from 'react'

import { DeleteModal } from '../../components/molecules/DeleteModal'
import { ToggleTemplate } from '../../components/molecules/ToggleTemplate'
import { EditQuizLibraryModal } from '../../components/organisms/EditQuizLibraryModal'
import { ManageEntitiesViewTemplate } from '../../components/organisms/ManageEntitiesViewTemplate'
import { videoStyles } from '../../styles/VideoStyles'
import { QuizType, UpdateQuizType } from '../../types/types'
import { sortItemsByCreatedAtString } from '../../utils/helper'
import {
    createQuiz, deleteQuiz, getQuizzes, updateQuiz
} from '../../utils/services/quiz-services'
import { fireWrap, wrap } from '../../utils/wrapper'
import { Loading } from '../Loading'

const filterQuizLibrary = (filter: string, item: QuizType) => {
    return item.title?.toLowerCase().includes(filter.toLowerCase().trim())
        || item.topics?.map((a) => a.toLowerCase().includes(filter.toLowerCase().trim()))
}

export const QuizLibraryPage: React.FC = () => {

    const [creating, setCreating] = React.useState(false)
    const [items, setItems] = React.useState<QuizType[]>([])
    const [editedItem, setEditedItem] = React.useState<QuizType>()
    const [deletingItems, setDeletingItems] = React.useState<QuizType[]>([])
    const [busy, setBusy] = React.useState(false)
    const classes = videoStyles()

    const convertItem = (v: QuizType) => {
        return [
            <p style={{ maxWidth: '80%' }}>{v.title}</p>,
            v.categories.map((c) => {
                return (
                    <p key={c} style={{ display: 'flex', flexDirection: 'column' }}>{c}</p>
                )
            }),

            <p>{v.questions?.length}</p>,
            <div>
                {v.topics?.map((t) => {
                    return (
                        <p key={t} className={classes.tag}>{t}</p>
                    )
                })}
            </div>,
            <div>
                <ToggleTemplate />
            </div>
        ]
    }

    const handleDelete = async () => {
        await wrap(async () => {
            await Promise.all(
                deletingItems.map((item) => deleteQuiz(item.id))
            )

            setItems(
                items.filter((item) => !deletingItems.includes(item))
            )
            setDeletingItems([])
        }, {
            operation: 'deleting quiz(zes)',
            showSuccess: true,
            stateFunc: setBusy
        })
    }

    const handleFinished = async (save: boolean, item: UpdateQuizType, isNew: boolean) => {

        if (save && item) {
            await wrap(async () => {
                if (isNew) {
                    setItems([await createQuiz(item), ...items])
                } else {
                    const updated = await updateQuiz(editedItem!.id, item)
                    setItems(
                        items.map(
                            (i) => (i.id === editedItem!.id ? updated : i)
                        )
                    )
                }
            }, {
                operation: isNew ? 'Creating Quiz' : 'Quiz Video',
                showSuccess: true,
                stateFunc: setBusy
            })
        }

        setEditedItem(undefined)
        setCreating(false)
    }

    React.useEffect(() => {
        fireWrap(async () => {
            const quizzes = await getQuizzes()
            setItems(sortItemsByCreatedAtString(quizzes))
        }, {
            operation: 'loading quizzes',
            stateFunc: setBusy
        })
    }, [])

    if (busy) {
        return (
            <Loading />
        )
    }
    return (
        <ManageEntitiesViewTemplate
            searchComponent
            backgroundColor="#278753"
            icon={<HelpOutlineIcon style={{ color: '#FFFFFF' }} />}
            title="Quizbibliotek"
            items={items}
            filterItem={filterQuizLibrary}
            headers={['Navn', 'Kategori', 'Antal spørgsmål', 'Emne', 'Svarrækkefølge:  Frivillig']}
            convertItem={convertItem}
            canCreate
            canEdit
            canDelete
            onCreate={() => setCreating(true)}
            onDelete={setDeletingItems}
            onEdit={setEditedItem}>
            <DeleteModal
                onConfirmAction={handleDelete}
                onCancelAction={() => setDeletingItems([])}
                confirmActionButtonText="Ja, helt sikker"
                cancelActionButtonText="Nej"
                headerText="Er du sikker på at du vil slette denne quiz?"
                isOpen={deletingItems.length > 0}
                onClose={() => setDeletingItems([])} />
            <EditQuizLibraryModal
                item={editedItem}
                isBusy={busy}
                key={editedItem?.id}
                header={editedItem ? 'Rediger Quizz' : 'Opret Quizz'}
                isOpen={!!editedItem || creating}
                isNew={creating}
                onFinish={handleFinished} />
        </ManageEntitiesViewTemplate>
    )

}
