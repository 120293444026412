import VideocamIcon from '@material-ui/icons/Videocam'
import * as React from 'react'

import { DeleteModal } from '../../components/molecules/DeleteModal'
import { ToggleTemplate } from '../../components/molecules/ToggleTemplate'
import { EditVideoLibraryModal } from '../../components/organisms/EditVideoLibraryModal'
import { ManageEntitiesViewTemplate } from '../../components/organisms/ManageEntitiesViewTemplate'
import { videoStyles } from '../../styles/VideoStyles'
import { UpdateVideoType, VideoType } from '../../types/types'
import { sortItemsByCreatedAtString } from '../../utils/helper'
import {
    createVideo, deleteVideo, getVideos, updateVideo
} from '../../utils/services/video-library-services'
import { fireWrap, wrap } from '../../utils/wrapper'
import { Loading } from '../Loading'

const filterVideoLibrary = (filter: string, item: VideoType) => {
    return !!item.title?.toLowerCase().includes(filter.toLowerCase())
        || !!item.mainFile?.name?.toLowerCase().includes(filter.toLowerCase())
        || !!item.supplementUri?.map((a) => a.toLowerCase().includes(filter.toLowerCase()))
        || !!item.resultsFile?.name?.toLowerCase().includes(filter.toLowerCase())
}

export const VideoLibraryPage: React.FC = () => {
    const [creating, setCreating] = React.useState(false)
    const [items, setItems] = React.useState<VideoType[]>([])
    const [editedItem, setEditedItem] = React.useState<VideoType>()
    const [deletingItems, setDeletingItems] = React.useState<VideoType[]>([])
    const [busy, setBusy] = React.useState(false)

    const classes = videoStyles()

    const convertItem = (v: VideoType) => {
        return [
            <img style={{ maxWidth: 200 }} key={-1} src={v.thumbnailUri} alt={v.title}/>,
            <p key={0} style={{ maxWidth: '80%' }}>{v.title}</p>,
            <div key={1}>
                {v.categories?.map((c) => {
                    return (
                        <p key={c}>{c}</p>
                    )
                })}
            </div>,
            <p key={2}>{v.fact}</p>,
            <p key={3}>{v.description}</p>,
            <div key={4}>
                {v.topics?.map((t) => {
                    return (
                        <p key={t} className={classes.tag}>{t}</p>
                    )
                })}
            </div>,
            <p key={5}>{v.mainFile?.name}</p>,
            <div key={6}>
                <ToggleTemplate/>
            </div>
        ]
    }

    const handleDelete = async () => {
        await wrap(async () => {
            await Promise.all(
                deletingItems.map((item) => deleteVideo(item.id))
            )

            setItems(
                items.filter((item) => !deletingItems.includes(item))
            )

            setDeletingItems([])
        }, {
            operation: 'Deleting Video(s)',
            showSuccess: true,
            stateFunc: setBusy
        })
    }

    const handleFinished = async (save: boolean, item: UpdateVideoType, isNew: boolean) => {
        if (save && item) {
            await wrap(async () => {
                if (isNew) {
                    setItems([await createVideo(item), ...items])
                } else {
                    const updated = await updateVideo(editedItem!.id, item)
                    setItems(
                        items.map(
                            (i) => (i.id === editedItem!.id ? updated : i)
                        )
                    )
                }
            }, {
                operation: isNew ? 'Creating Video' : 'Updating Video',
                showSuccess: true,
                stateFunc: setBusy
            })
        }

        setEditedItem(undefined)
        setCreating(false)
    }

    React.useEffect(() => {
        fireWrap(async () => {
            const videos = await getVideos()
            setItems(sortItemsByCreatedAtString(videos))
        }, {
            operation: 'Loading Videos',
            stateFunc: setBusy
        })
    }, [])

    if (busy) {
        return (
            <Loading/>
        )
    }
    return (
        <ManageEntitiesViewTemplate
            searchComponent
            backgroundColor="#278753"
            icon={<VideocamIcon style={{ color: '#FFFFFF' }}/>}
            title="Videobibliotek"
            items={items}
            filterItem={filterVideoLibrary}
            headers={['  ', 'Titel', 'Kategori', 'Facit', 'Beskrivelse', 'Emne', 'Filer', 'Offentlig']}
            convertItem={convertItem}
            canCreate
            canEdit
            canDelete
            onCreate={() => setCreating(true)}
            onDelete={setDeletingItems}
            onEdit={setEditedItem}>
            <DeleteModal
                onConfirmAction={handleDelete}
                onCancelAction={() => setDeletingItems([])}
                confirmActionButtonText="Ja, helt sikker"
                cancelActionButtonText="Nej"
                headerText="Er du sikker på at du vil slette denne kamagne?"
                isOpen={deletingItems.length > 0}
                onClose={() => setDeletingItems([])}/>
            <EditVideoLibraryModal
                item={editedItem}
                isBusy={busy}
                key={editedItem?.id}
                header={editedItem ? 'Rediger Video' : 'Opret Video'}
                isOpen={!!editedItem || creating}
                isNew={creating}
                onFinish={handleFinished}/>
        </ManageEntitiesViewTemplate>
    )
}
