/* eslint-disable @typescript-eslint/no-unused-vars */
import FaceIcon from '@material-ui/icons/Face'
import moment from 'moment'
import * as React from 'react'

import { DeleteModal } from '../../components/molecules/DeleteModal'
import { EditStudentModal } from '../../components/organisms/EditStudentModal'
import { ManageEntitiesViewTemplate } from '../../components/organisms/ManageEntitiesViewTemplate'
import { StudentType } from '../../types/types'
import { showMessage } from '../../utils/notifications'
import { deleteStudentUser, getStudents } from '../../utils/services/student-services'
import { createTeacherUser } from '../../utils/services/teacher-services'
import { fireWrap, wrap } from '../../utils/wrapper'
import { Loading } from '../Loading'

const filterStudent = (filter: string, item: StudentType) => {
    return item.firstName.toLowerCase().includes(filter.toLowerCase())
        || item.lastName.toLowerCase().includes(filter.toLowerCase())
        || item.username.toLowerCase().includes(filter.toLowerCase())
}

export const StudentsProfilesPage: React.FC = () => {

    const [items, setItems] = React.useState<StudentType[]>([])
    const [editedItem, setEditedItem] = React.useState<StudentType>()
    const [deletingItems, setDeletingItems] = React.useState<StudentType[]>([])
    const [busy, setBusy] = React.useState(false)

    const handleDelete = async () => {
        await wrap(async () => {
            await Promise.all(
                deletingItems.map((item) => deleteStudentUser(item.id))
            )
        },
        {
            operation: 'deleting student(s)',
            stateFunc: setBusy
        })

        const newList = items.filter((item) => !deletingItems.includes(item))
        setItems(newList)
        setDeletingItems([])
    }

    const handleClose = async (save: boolean, item: { email: string, password: string }, isNew: boolean) => {

        if (save && item) {
            if (isNew) {
                createTeacherUser(item).then((ok) => {
                    if (!ok) {
                        showMessage(`Failed to create teacher ${item.email}!`, 'error')
                    } else {
                        showMessage(`The teacher  ${item.email} has been created.`, 'success')
                    }
                })
            } else {
                // todo: update in db .....(when api ready)
            }
        }
        setEditedItem(undefined)
    }

    React.useEffect(() => {
        fireWrap(async () => {
            setItems(await getStudents())
        }, {
            operation: 'loading students',
            stateFunc: setBusy
        })
    }, [])
    
    if (busy) {
        return (
            <Loading/>
        )
    }
    return (
        <ManageEntitiesViewTemplate
            searchComponent
            backgroundColor="#14A2B8"
            icon={<FaceIcon style={{ color: '#FFFFFF' }}/>}
            title="Elevprofiler"
            items={items}
            headers={['UserName', 'Fornavn', 'Efternavn', 'Oprettet']}
            convertItem={(s) => [s.username, s.firstName, s.lastName, moment.utc(s.createdAt).format('DD/MM/YYYY')]}
            filterItem={filterStudent}
            canEdit
            canDelete
            onDelete={setDeletingItems}
            onEdit={setEditedItem}>
            <DeleteModal
                onConfirmAction={handleDelete}
                onCancelAction={() => setDeletingItems([])}
                confirmActionButtonText="Ja, helt sikker"
                cancelActionButtonText="Nej"
                headerText="Er du sikker på at du vil slette denne elev?"
                isOpen={deletingItems.length > 0}
                onClose={() => setDeletingItems([])}/>
            <EditStudentModal
                isNew={false}
                onFinish={handleClose}
                studentId={editedItem?.id}
                isOpen={!!editedItem}
                header="Rediger elevprofiler"/>

        </ManageEntitiesViewTemplate>
    )
}
