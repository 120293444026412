import { API } from 'aws-amplify'

import { AdminType, CreateAdminType } from '../../types/types'

export const createAdminUser = async (params: CreateAdminType): Promise<AdminType> => {
    return API.post('api', '/admins', {
        body: params
    })
}

export const getAdmins = async (): Promise<AdminType[]> => {
    return API.get('api', '/admins', {})
}

export const deleteAdmin = async (email: string): Promise<void> => {
    return API.del('api', `/admins/${email}`, {})
}

export const resetAdminPassword = async (email?: string): Promise<void> => {
    return API.post('api', `/admins/${email || 'me'}/reset`, {})
}
