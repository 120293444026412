import { API } from 'aws-amplify'

import { HomeworkType } from '../../types/types'

export const getHomeworks = async (): Promise<HomeworkType[]> => {
    return API.get('api', '/homeworks', {})
}

export const deleteHomework = async (homeworkId: string): Promise<void> => {
    return API.del('api', `/homeworks/${homeworkId}`, {})
}
