import { ThemeProvider } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import { Hub } from 'aws-amplify'
import React from 'react'
import {
    BrowserRouter as Router, Route, Switch
} from 'react-router-dom'

import { NotificationPopup, Severity } from '../components/molecules/NotificationPopup'
import { primary, primaryContrast } from '../utils/colors'
import { AppContext } from '../utils/context'
import { channelName } from '../utils/notifications'
import Routes from '../utils/Routes'
import { useCognito } from '../utils/services/cognito-services'
import { AdminProfilesPage } from './AdminProfiles'
import CampaignsPage from './Campaigns'
import { CommunityQuizLibraryPage } from './CommunityQuizLibrary'
import { HomePage } from './Home'
import { HomeworksPage } from './Homeworks'
import { Loading } from './Loading'
import { Login } from './Login'
import { PDFLibraryPage } from './PDFLibrary'
import { PresentationPage } from './Presentation'
import { PricePage } from './Price'
import { ProfessorProfilesPage } from './ProfessorProfiles'
import PuzzleHome from './Puzzle/pages'
import { QuizLibraryPage } from './QuizLibrary'
import { SchoolsPage } from './Schools'
import { SoundsLibraryPage } from './SoundsLibrary'
import { StudentsProfilesPage } from './StudentsProfiles'
import { VideoLibraryPage } from './VideoLibrary'

const theme = createTheme({
    palette: {
        primary: { main: primary, contrastText: primaryContrast },
        secondary: { main: '#A0A0C7' }
    },
    typography: {
        fontFamily: [
            'Encode Sans',
            'sans-serif',
        ].join(','),
    },
})

export default function RootRouter() {
    const [loading, setLoading] = React.useState(false)
    const { signedIn } = useCognito()
    const [showNotification, setShowNotification] = React.useState(false)
    const [notificationSeverity, setNotificationSeverity] = React.useState<Severity>('info')
    const [notificationMessage, setNotificationMessage] = React.useState('')

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleContext = (options: boolean): number => {
        setLoading(true)
        return 0
    }

    const notificationListener = React.useCallback(({ payload: { data: { message, severity } } }) => {
        console.log(severity, message)

        setShowNotification(true)
        setNotificationSeverity(severity)
        setNotificationMessage(message)
    }, [])

    React.useEffect(() => {
        Hub.listen(channelName, notificationListener)
        return () => Hub.remove(channelName, notificationListener)
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <NotificationPopup
                onClose={() => setShowNotification(false)}
                show={showNotification}
                severity={notificationSeverity}
                message={notificationMessage} />
            <AppContext.Provider value={handleContext}>
                {loading ? <Loading />
                    : (
                        <Router>
                            <Switch>
                                {signedIn ? (
                                    <>
                                        <Route exact path={Routes.PuzzleHome.path}>
                                            <PuzzleHome />
                                        </Route>
                                        <Route key={Routes.Home.path} exact path={Routes.Home.path}>
                                            <HomePage />
                                        </Route>
                                        <Route key={Routes.Presentation.path} exact path={Routes.Presentation.path}>
                                            <PresentationPage />
                                        </Route>
                                        <Route key={Routes.VideoLibrary.path} exact path={Routes.VideoLibrary.path}>
                                            <VideoLibraryPage />
                                        </Route>
                                        <Route key={Routes.SoundLibrary.path} exact path={Routes.SoundLibrary.path}>
                                            <SoundsLibraryPage />
                                        </Route>
                                        <Route key={Routes.PDFLibrary.path} exact path={Routes.PDFLibrary.path}>
                                            <PDFLibraryPage />
                                        </Route>
                                        <Route
                                            key={Routes.ProfessorProfiles.path}
                                            exact
                                            path={Routes.ProfessorProfiles.path}>
                                            <ProfessorProfilesPage />
                                        </Route>
                                        <Route
                                            key={Routes.StudentsProfiles.path}
                                            exact
                                            path={Routes.StudentsProfiles.path}>
                                            <StudentsProfilesPage />
                                        </Route>
                                        <Route key={Routes.AdminProfiles.path} exact path={Routes.AdminProfiles.path}>
                                            <AdminProfilesPage />
                                        </Route>
                                        <Route key={Routes.HomeworkLinks.path} exact path={Routes.HomeworkLinks.path}>
                                            <HomeworksPage />
                                        </Route>
                                        <Route key={Routes.Price.path} exact path={Routes.Price.path}>
                                            <PricePage />
                                        </Route>
                                        <Route key={Routes.Campaigns.path} exact path={Routes.Campaigns.path}>
                                            <CampaignsPage />
                                        </Route>
                                        <Route key={Routes.QuizLibrary.path} exact path={Routes.QuizLibrary.path}>
                                            <QuizLibraryPage />
                                        </Route>
                                        <Route
                                            key={Routes.CommunityQuizLibrary.path}
                                            exact
                                            path={Routes.CommunityQuizLibrary.path}>
                                            <CommunityQuizLibraryPage />
                                        </Route>
                                        <Route key={Routes.Schools.path} exact path={Routes.Schools.path}>
                                            <SchoolsPage />
                                        </Route>
                                    </>
                                )
                                    : (
                                        <Route key="*" exact path="*">
                                            <Login />
                                        </Route>
                                    )}
                                <Route key="*" exact path="*">
                                    <h1>404 Not found</h1>
                                </Route>
                            </Switch>
                        </Router>
                    )}
            </AppContext.Provider>
        </ThemeProvider>
    )
}
