import {
    AppBar, Container, createStyles, CssBaseline, Drawer, IconButton, List, ListItem, ListItemIcon,
    ListItemText, makeStyles, Theme, Toolbar, useTheme
} from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import MenuIcon from '@material-ui/icons/Menu'
import { Auth, Hub } from 'aws-amplify'
import clsx from 'clsx'
import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { primaryContrast } from '../../utils/colors'
import { Routes } from '../../utils/Routes'
import { LogoutModal } from '../molecules/LogoutModal'
import { Body } from './Body'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,

    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: '#485058',
        color: '#FFFFFF'
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        backgroundColor: '#F1ECE9',
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,

    },
    containerNavBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: 10,
        paddingLeft: 10,
    },
    contentShift: {
        flexGrow: 1,
        height: '100%',
        backgroundColor: '#F1ECE9',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
    },
    itemClicked: {
        backgroundColor: '#FFF',
        '&:hover': {
            background: '#FFF',
            paddingLeft: 20,
        },
    },
    itemName: {
        background: primaryContrast,
        transition: '0.3s',
        '&:hover': {
            background: '#d7443f',
            paddingLeft: 20,
        },

    }
}),)

export const BodyWithTopBar: React.FC = ({ children }) => {
    const classes = useStyles()
    const theme = useTheme()
    const history = useHistory()

    const [open, setOpen] = React.useState(localStorage.getItem('DrawerOpen') === 'true')
    const [logout, setLogout] = React.useState(false)
    const [loggingOut, setLoggingOut] = React.useState(false)

    const handleLogout = async () => {
        setLoggingOut(true)

        await Auth.signOut()

        setLoggingOut(false)

        Hub.dispatch('auth', { event: 'signOut' }) // todo: this should be removed!
    }

    const handleDrawerOpen = () => {
        setOpen(true)
        localStorage.setItem('DrawerOpen', 'true')
    }

    const handleDrawerClose = () => {
        setOpen(false)
        localStorage.setItem('DrawerOpen', 'false')

    }

    return (
        <Body>
            <CssBaseline />
            <AppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: open, })}>
                <Toolbar>
                    <IconButton
                        style={{ color: '#FFFFFF' }}
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}>
                        <MenuIcon />
                    </IconButton>
                    <Container className={classes.containerNavBar}>
                        <span
                            style={{ color: '#FFFFFF', fontSize: 22 }}>
                            Geekster.dk - Administrator
                        </span>
                        <span>
                            <ExitToAppIcon
                                onClick={() => setLogout(true)}
                                style={{ color: '#FFFFFF', fontSize: 50, cursor: 'pointer' }} />
                        </span>
                    </Container>
                </Toolbar>
            </AppBar>
            <Drawer
                style={{ backgroundColor: '#485058' }}
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}>
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {/* eslint-disable-next-line react/jsx-no-undef */}
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>

                <List style={{ backgroundColor: primaryContrast }}>
                    {Routes.map((item) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <ListItem
                            className={classes.itemName}
                            button
                            key={item.path}
                            onClick={() => history.push(item.path)}>
                            <ListItemIcon style={{ color: '#FFFFFF' }}>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <LogoutModal
                disableAccept={loggingOut}
                onConfirmAction={handleLogout}
                onClose={() => setLogout(true)}
                onCancelAction={() => setLogout(false)}
                isOpen={logout}
                headerText="Er du sikker på at du vil logge ud?"
                confirmActionButtonText="Ja, helt sikker"
                cancelActionButtonText="Nej"/>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}>
                <div className={classes.drawerHeader} />
                {children}
            </main>
        </Body>
    )
}
