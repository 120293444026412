import SearchBar from 'material-ui-search-bar'
import * as React from 'react'
import { useEffect } from 'react'

import { SearchBarLink } from '../atoms/SearchBarLink'
import { SimpleTableWithPagination } from '../molecules/SimpleTableWithPagination'
import { TitleContainer } from '../molecules/TitleContainer'
import { ToolBarContainer } from '../molecules/ToolBarContainer'
import { BodyWithTopBar } from '../wrappers/BodyWithTopBar'

interface Props {
    searchComponent?: boolean;
    canCreate?: boolean;
    canEdit?: boolean,
    canDelete?: boolean
    headers: string[],
    items: any[],
    icon: JSX.Element,
    backgroundColor: string,
    title: string,
    convertItem(item: any): any[]
    filterItem?(filter: string, item: any): boolean,
    onDelete?(items: any[]): void,
    onEdit?(item: any): void,
    onCreate?(): void
}

export const ManageEntitiesViewTemplate: React.FC<Props> = (
    {
        searchComponent,
        canCreate: cc,
        canEdit: ce,
        canDelete: cd, 
        headers,
        items,
        icon,
        backgroundColor,
        title,
        convertItem,
        filterItem,
        onDelete,
        onEdit,
        onCreate,
        children,
    }
) => {
    const [canEdit, setCanEdit] = React.useState(false)
    const [canDelete, setCanDelete] = React.useState(false)
    const [selection, setSelection] = React.useState<any[]>([]) // TODO: refresh selection if items are updated
    const [filter, setFilter] = React.useState<string>()
    const [filteredItems, setFilteredItems] = React.useState(items)

    useEffect(() => {
        setFilteredItems(items)

        if (filter) {
            handleSearch(filter)
        }
    }, [items])

    const handleSearch = (f: string) => {
        setFilter(f)

        if (filterItem && f && searchComponent) {
            const filterFunc = (i: any) => filterItem(f, i)
            setFilteredItems(items.filter(filterFunc))
        } else {
            setFilteredItems(items)
        }
    }

    const handleSelectionChange = (s: number[]) => {
        setSelection(s.map((index) => filteredItems[index]))

        setCanEdit(s.length === 1)
        setCanDelete(s.length > 0)
        setCanDelete(s.length > 0)
    }

    return (
        <BodyWithTopBar>
            <TitleContainer
                icon={icon}
                background={backgroundColor}
                title={title} />

            <ToolBarContainer>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {cc && (
                        <SearchBarLink
                            text="Opret ny"
                            onClickLink={() => onCreate && onCreate()}/>
                    )}
                    {ce && (
                        <SearchBarLink 
                            text="Rediger" 
                            disabled={!canEdit} 
                            onClickLink={() => onEdit && onEdit(selection[0])}/>
                    )}
                    {cd && (
                        <SearchBarLink 
                            text="Slet" 
                            disabled={!canDelete}
                            onClickLink={() => onDelete && onDelete(selection)}/>
                    )}
                </div>
                {searchComponent && (
                    <div style={{
                        width: 300, flexGrow: 1, display: 'flex', justifyContent: 'flex-end' 
                    }}>
                        <SearchBar
                            value={filter}
                            onChange={handleSearch}
                            onCancelSearch={() => setFilter(undefined)}/>
                    </div>
                )}
            </ToolBarContainer>

            <SimpleTableWithPagination
                content={filteredItems.map(convertItem)}
                selectionMode="multi"
                headers={headers}
                arialLabel={title}
                onSelectionChange={handleSelectionChange}/>
            {children}
        </BodyWithTopBar>
    ) 
}
