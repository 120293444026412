import { Button, Container } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import React, { useState } from 'react'

import { buttonStyles } from '../../styles/ButtonsStyles'
import { modalStyles } from '../../styles/ModalStyles'
import { resetStudentPassword } from '../../utils/services/student-services'
import { wrap } from '../../utils/wrapper'
import { EditModalTemplate, EditModalTemplateProps } from '../molecules/EditModalTemplate'

type Props = EditModalTemplateProps & {
    studentId?: string
 }

export const EditStudentModal: React.FC<Props> = (props) => {
    const classes = buttonStyles()
    const classesModal = modalStyles()

    const [busy, setBusy] = useState<boolean>(false)

    const resetPassword = async () => {
        await wrap(async () => {
            await resetStudentPassword(props.studentId!!)
        }, {
            operation: 'Resetting Password',
            stateFunc: setBusy
        })
    }

    const newProps = {
        ...props,
        isBusy: props.isBusy || busy,
    }
    
    return (
        <EditModalTemplate
            className={classesModal.paper}
            header="Rediger elevprofiler"
            {...newProps}>
            <Container className={classes.buttonsWrapper}>
                <Button
                    disabled={!props.studentId || props.isBusy}
                    onClick={resetPassword}
                    variant="contained"
                    size="small"
                    className={classes.alertButton}
                    startIcon={<RotateLeftIcon />}>Nulstil password
                </Button>
                <Button
                    variant="contained"
                    size="small"
                    disabled={props.isBusy}
                    className={classes.cancelButton}
                    startIcon={<CancelIcon/>}>
                    Afslut
                </Button>
            </Container>
        </EditModalTemplate>
    )
}
