import { API } from 'aws-amplify'

import { PriceType, UpdatePriceType } from '../../types/types'

export const createPrice = async (params: UpdatePriceType): Promise<PriceType> => {
    return API.post('api', '/prices', {
        body: params
    })
}

export const getPrices = async (): Promise<PriceType[]> => {
    return API.get('api', '/prices', {})
}

export const updatePrice = async (priceId: string, params: UpdatePriceType): Promise<PriceType> => {
    return API.put('api', `/prices/${priceId}`, {
        body: params
    })
}

export const deletePrice = async (priceId: string): Promise<void> => {
    await API.del('api', `/prices/${priceId}`, {})
}
