import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import BeenhereIcon from '@material-ui/icons/Beenhere'
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard'
import ExtensionIcon from '@material-ui/icons/Extension'
import FaceIcon from '@material-ui/icons/Face'
import ForumIcon from '@material-ui/icons/Forum'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import HomeIcon from '@material-ui/icons/Home'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic'
import PeopleIcon from '@material-ui/icons/People'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import PublicIcon from '@material-ui/icons/Public'
import SchoolIcon from '@material-ui/icons/School'
import VideocamIcon from '@material-ui/icons/Videocam'
import * as React from 'react'

type Route = {
    path: string
    name: string
    icon?: JSX.Element
    secure: boolean
}

const Login: Route = {
    path: '/login',
    name: 'Login',
    secure: false
}

const Home: Route = {
    path: '/',
    name: 'Hjem',
    icon: <HomeIcon />,
    secure: true
}

const Presentation: Route = {
    path: '/praesentation',
    name: 'Præsentation',
    icon: <PublicIcon />,
    secure: true
}

const VideoLibrary: Route = {
    path: '/videobibliotek',
    name: 'Geeksters videoer',
    icon: <VideocamIcon />,
    secure: true
}

const SoundLibrary: Route = {
    path: '/soundbibliotek',
    name: 'Geeksters Lyd',
    icon: <LibraryMusicIcon />,
    secure: true
}

const PDFLibrary: Route = {
    path: '/pdfbibliotek',
    name: 'Geeksters PDF',
    icon: <PictureAsPdfIcon />,
    secure: true
}

const ProfessorProfiles: Route = {
    path: '/laererprofiler',
    name: 'Lærerprofiler',
    icon: <PeopleIcon />,
    secure: true
}

const StudentsProfiles: Route = {
    path: '/elevprofiler',
    name: 'Elevprofiler',
    icon: <FaceIcon />,
    secure: true
}

const AdminProfiles: Route = {
    path: '/adminprofiler',
    name: 'Adminprofiler',
    icon: <BeenhereIcon />,
    secure: true
}

const HomeworkLinks: Route = {
    path: '/lektierlinks',
    name: 'Lektierlinks',
    icon: <ImportContactsIcon />,
    secure: true
}

const Price: Route = {
    path: '/priser',
    name: 'Priser',
    icon: <AttachMoneyIcon />,
    secure: true
}

const Campaigns: Route = {
    path: '/kampagner',
    name: 'Kampagner',
    icon: <CardGiftcardIcon />,
    secure: true
}
const QuizLibrary: Route = {
    path: '/Quizbibliotek',
    name: 'Geeksters quizzer',
    icon: <HelpOutlineIcon />,
    secure: true
}
const CommunityQuizLibrary: Route = {
    path: '/communityquizbibliotek',
    name: 'Vores quizzer',
    icon: <ForumIcon />,
    secure: true
}

const Schools: Route = {
    path: '/scholer',
    name: 'Scholer',
    icon: <SchoolIcon />,
    secure: true
}

const PuzzleHome: Route = {
    path: '/pusle',
    name: 'Pusle',
    icon: <ExtensionIcon />,
    secure: true
}

export const Routes = [
    Home,
    Presentation,
    VideoLibrary,
    SoundLibrary,
    PDFLibrary,
    ProfessorProfiles,
    StudentsProfiles,
    AdminProfiles,
    HomeworkLinks,
    Price,
    Campaigns,
    QuizLibrary,
    CommunityQuizLibrary,
    Schools,
    PuzzleHome,
]

export default {
    PuzzleHome,
    PDFLibrary,
    Login,
    Home,
    Presentation,
    VideoLibrary,
    SoundLibrary,
    ProfessorProfiles,
    StudentsProfiles,
    AdminProfiles,
    HomeworkLinks,
    Price,
    Campaigns,
    QuizLibrary,
    CommunityQuizLibrary,
    Schools
}
