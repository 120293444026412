import SchoolIcon from '@material-ui/icons/School'
import moment from 'moment'
import * as React from 'react'

import { DeleteModal } from '../../components/molecules/DeleteModal'
import { EditSchoolModal } from '../../components/organisms/EditSchoolModal'
import { ManageEntitiesViewTemplate } from '../../components/organisms/ManageEntitiesViewTemplate'
import { SchoolType, TeacherType } from '../../types/types'
import { showMessage } from '../../utils/notifications'
import {
    createSchool, getSchools, updateSchool
} from '../../utils/services/school-services'
import { wrap } from '../../utils/wrapper'
import { Loading } from '../Loading'

const filterSchool = (filter: string, item: TeacherType) => {
    return item.school?.toLowerCase().includes(filter.toLowerCase())
}

export const SchoolsPage: React.FC = () => {

    const [creating, setCreating] = React.useState(false)
    const [schools, setSchools] = React.useState<Partial<SchoolType>[]>([])
    const [editedItem, setEditedItem] = React.useState<SchoolType>()
    const [deletingItems, setDeletingItems] = React.useState<Partial<SchoolType>[]>([])

    const [busy, setBusy] = React.useState(false)

    const handleDeleteSchools = async () => {
        setBusy(true)

        // TODO: implement deleting schools
        // await Promise.all(
        //     deletingItems.map((item) => deleteSchool(item.id))
        // )

        showMessage(`The school ${deletingItems.map((c) => c.name)} has been deleted.`, 'success')

        const newList = schools.filter((item) => !deletingItems.includes(item))

        setSchools(newList)
        setDeletingItems([])
        setBusy(false)
    }

    const handleFinished = async (save: boolean, item:
        { name: string, uniLogId: number, expires: string }, isNew: boolean) => {
        if (save && item) {
            if (isNew) {
                await wrap(async () => {
                    setSchools([...schools, await createSchool(item)])
                }, {
                    operation: 'creating schools',
                    stateFunc: setBusy,
                    showSuccess: true
                })
            } else {
                await wrap(async () => {
                    const cmp = await updateSchool(editedItem!.id, item)
                    setSchools(
                        schools.map(
                            (i) => (i.id === editedItem!.id ? cmp : i)
                        )
                    )
                }, {
                    operation: 'updating schools',
                    stateFunc: setBusy,
                    showSuccess: true
                })
            }
        }

        setEditedItem(undefined)
        setCreating(false)
    }

    React.useEffect(() => {
        (async () => {
            setBusy(true)

            try {
                const transformedSchools = await getSchools()
                
                setSchools(transformedSchools)
            } catch (error) {
                showMessage('Error on loading schools', 'error')
            }

            setBusy(false)
        })()
    }, [])

    if (busy) {
        return (
            <Loading/>
        )
    }
    return (
        <ManageEntitiesViewTemplate
            searchComponent
            backgroundColor="#1EC997"
            icon={<SchoolIcon style={{ color: '#FFFFFF' }}/>}
            title="Scholer"
            items={schools}
            headers={['Navn', 'Udløber', 'Oprettede hjemmeopgaver']}
            convertItem={(s: SchoolType) => [s.name, moment(s.expires).format('DD/MM/YYYY'), s.noOfCreatedHomeworks]}
            filterItem={filterSchool}
            canCreate
            canEdit
            canDelete
            onCreate={() => setCreating(true)}
            onDelete={setDeletingItems}
            onEdit={setEditedItem}>
            <DeleteModal
                onConfirmAction={handleDeleteSchools}
                onCancelAction={() => setDeletingItems([])}
                confirmActionButtonText="Ja, helt sikker"
                cancelActionButtonText="Nej"
                headerText="Er du sikker på at du vil slette denne scholer?"
                isOpen={deletingItems.length > 0}
                onClose={() => setDeletingItems([])}/>
            <EditSchoolModal
                key={editedItem?.id}
                isBusy={busy}
                header={editedItem ? 'Rediger Skole' : 'Opret Skole'}
                isOpen={!!editedItem || creating}
                name={editedItem?.name}
                // @ts-ignore
                createdAt={editedItem?.expires}
                uniLogId={editedItem?.uniLogId}
                isNew={creating}
                onFinish={handleFinished}/>
        </ManageEntitiesViewTemplate>
    )
}
