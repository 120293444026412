import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import * as React from 'react'

import { EditCommunityQuizModal } from '../../components/organisms/EditCommunityQuizModal'
import { ManageEntitiesViewTemplate } from '../../components/organisms/ManageEntitiesViewTemplate'
import { videoStyles } from '../../styles/VideoStyles'
import { CommunityQuizType, UpdateCommunityQuizType } from '../../types/types'
import { sortItemsByCreatedAtString } from '../../utils/helper'
import { getCommunityQuizzes, updateCommunityQuizStatus } from '../../utils/services/community-quiz-services'
import { filterQuizLibrary } from '../../utils/services/quiz-services'
import { fireWrap, wrap } from '../../utils/wrapper'
import { Loading } from '../Loading'

export const CommunityQuizLibraryPage: React.FC = () => {

    const [items, setItems] = React.useState<CommunityQuizType[]>([])
    const [editedItem, setEditedItem] = React.useState<CommunityQuizType>()
    const [busy, setBusy] = React.useState(false)
    const classes = videoStyles()

    const convertItem = (v: CommunityQuizType) => {
        return [
            <p style={{ maxWidth: '80%' }}>{v.title}</p>,
            <p>{v.description}</p>,
            <p>{v.categories?.join(', ')}</p>,
            <p>{v.questions?.length}</p>,
            <div>
                {v.topics?.map((t) => {
                    return (
                        <p key={t} className={classes.tag}>{t}</p>
                    )
                })}
            </div>,
            <p>{v.status}</p>
        ]
    }

    React.useEffect(() => {
        fireWrap(async () => {
            const fetchedCommunityQuizzes = await getCommunityQuizzes()
            setItems(sortItemsByCreatedAtString(fetchedCommunityQuizzes))
        }, {
            operation: 'loading quizzes',
            stateFunc: setBusy
        })
    }, [])

    const handleFinished = async (save: boolean, item: UpdateCommunityQuizType, isNew: boolean) => {

        if (save && item) {
            await wrap(async () => {
                const updated = await updateCommunityQuizStatus(editedItem!.id, item)
                setItems(
                    items.map(
                        (i) => (i.id === editedItem!.id ? updated : i)
                    )
                )
            }, {
                operation: isNew ? 'Creating Quiz' : 'Quiz Video',
                showSuccess: true,
                stateFunc: setBusy
            })
        }

        setEditedItem(undefined)
    }

    if (busy) {
        return (
            <Loading/>
        )
    }
    return (
        <ManageEntitiesViewTemplate
            searchComponent
            backgroundColor="#278753"
            icon={<HelpOutlineIcon style={{ color: '#FFFFFF' }}/>}
            title="Vores quizzer"
            items={items}
            filterItem={filterQuizLibrary}
            headers={['Navn', 'Beskrivelse', 'Kategori', 'Antal spørgsmål', 'Emne', 'Status']}
            convertItem={convertItem}
            canEdit
            onEdit={setEditedItem}>
            <EditCommunityQuizModal
                item={editedItem}
                isBusy={busy}
                key={editedItem?.id}
                header="Rediger Quizz"
                isOpen={!!editedItem}
                isNew={false}
                onFinish={handleFinished}/>
        </ManageEntitiesViewTemplate>
    )
    
}
