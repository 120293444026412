const sizeOptions = ['Vælg antal brikker', '3x3', '4x4']
const priFix = '' // "/math-puzzle";
const urls = {
    favicon: '/favicon.ico',
    logo: '/logo.png',
    winAudio: '/win.mp3',
    beep: '/beep.mp3',
}

export { sizeOptions, priFix, urls }
