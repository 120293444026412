import { makeStyles } from '@material-ui/core/styles'

export const TablesStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    cell: {
        fontWeight: 600,
        fontSize: 16
    },
    cellUnit: {
        backgroundColor: 'white',
        '&:hover, &:focus': {
            backgroundColor: '#e5f5e5',
            cursor: 'pointer',
        }
    },
    selectedCellUnit: {
        backgroundColor: '#BED9E7',
        '&:hover, &:focus': {
            cursor: 'pointer',
        }

    }
})
