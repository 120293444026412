import { Typography } from '@material-ui/core'
import React from 'react'
import { Pie } from 'react-chartjs-2'

type Props = {
    privateVideos: number
    publicVideos: number
}

export const VideoStatisticsView: React.FC<Props> = ({ privateVideos, publicVideos }) => {
    const totalVideos = privateVideos + publicVideos
    const ratioOfPrivate = Math.floor(privateVideos / totalVideos) * 100
    const ratioOfPublic = 100 - ratioOfPrivate

    const pieChart = {
        labels: ['Offentlinge videoer', 'Pivate videoer'],
        datasets: [
            {
                data: [ratioOfPrivate, ratioOfPublic],
                backgroundColor: [
                    'rgba(22,67,107,0.8)',
                    'rgba(221,108,8)',

                ],
                borderColor: [
                    'rgba(4,39,64,0.8)',
                    'rgb(227,136,49)',
                ],
                borderWidth: 2,
            },
        ],
    }

    return (
        <div style={{ maxWidth: 400 }}>
            <Typography style={{ textAlign: 'center' }} variant="h5">Videobibliotek</Typography>
            <Typography
                style={{ textAlign: 'center' }}
                variant="h6">
                Antal Videoer: {totalVideos}
            </Typography>
            <Pie style={{ maxWidth: 400, maxHeight: 200 }} type={Pie} data={pieChart} />
        </div>
    )
}
