import { Link } from '@material-ui/core'
import * as React from 'react'

import { lightGrey, primary } from '../../utils/colors'

interface Props {
    text: string;
    disabled?: boolean
    onClickLink?: () => void

}

export const SearchBarLink: React.FC<Props> = ({
    text, onClickLink, disabled,
}) => {

    const handleClick = () => {
        if (onClickLink && !disabled) {
            onClickLink()
        }
    }

    const opacity = disabled ? 0.8 : 1
    const color = disabled ? lightGrey : primary
    const cursor = disabled ? 'auto' : 'pointer'
    return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
            style={{
                textDecoration: 'none',
                paddingLeft: 8,
                paddingRight: 8,
                cursor,
                opacity
            }}
            onClick={handleClick}>
            <p style={{ fontSize: 16, color, fontWeight: 500 }}>{text}</p>
        </Link>
    )
}
