import CheckIcon from '@material-ui/icons/Check'
import React, { useEffect } from 'react'

import { VimeoVideoType } from '../../types/types'
import { validateVimeoVideo } from '../../utils/services/video-library-services'
import { TextInputTemplate } from './TextInputTemplate'

type Props = {
    vimeoVideoId: string | undefined
    onChange?(vimeoVideo: VimeoVideoType | undefined): void
    onBusy?(yes: boolean): void,
    isDisabled?: boolean
}

const decodeVimeoImageId = (id: string): string | undefined => {
    const match = id.match(/^(?:.*vimeo\.com.*\/)?(\d{7,10})(?:\/.*)?$/)
    if (match) {
        return match[1]
    }

    return undefined
}

export const VimeoVideoInput: React.FC<Props> = ({
    vimeoVideoId: vmId, onChange, onBusy, isDisabled
}) => {

    const [value, setValue] = React.useState(vmId || '')
    const [loadingVideoMeta, setLoadingVideoMeta] = React.useState(false)
    const [state, setState] = React.useState<'unknown' | 'valid' | 'invalid'>('unknown')
    
    const vimeoVideoId = decodeVimeoImageId(value || '')

    useEffect(() => {
        validateDetails().then()
    }, [vmId])

    const setValue1 = (v: string) => {
        setValue(v)
        setState('unknown')
    }

    const handleKeyPress = (key: any, e: React.KeyboardEvent) => {
        if (key.toString() === 'Enter' && vimeoVideoId) {
            validateDetails().then()
            e.preventDefault()
        }
    }

    const validateDetails = async () => {
        let vimeoVideoDetails: VimeoVideoType | undefined

        if (vimeoVideoId && state === 'unknown') {
            setLoadingVideoMeta(true)
            if (onBusy) {
                onBusy(true)
            }

            try {
                vimeoVideoDetails = await validateVimeoVideo(vimeoVideoId)
            } catch (ex) {
                console.warn(`Could not get Vimeo details for ${vimeoVideoId}`, ex)
            }

            setLoadingVideoMeta(false)
            if (onBusy) {
                onBusy(false)
            }
        }

        if (onChange) {
            onChange(vimeoVideoDetails)
        }

        setState(vimeoVideoDetails ? 'valid' : 'invalid')
    }

    let underText = 'Video ID kan findes på din Vimeo profil'
    if (state === 'invalid') {
        underText = 'Id -videoen er ugyldig'
    } else if (state === 'valid') {
        underText = ''
    } 
    if (isDisabled) underText = ''

    return (
        <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'
        }}>
      
            <TextInputTemplate
                fullWidth
                underText={underText}
                label={loadingVideoMeta ? 'Indlæser...' : 'Video ID'}
                onBlur={validateDetails}
                onKeyPress={handleKeyPress}
                disabled={loadingVideoMeta || isDisabled}
                value={value}
                error={state === 'invalid'}
                onChange={setValue1}
                type="default"/>
            {state === 'valid' && (
                <CheckIcon style={{ color: 'green' }}/>
            )}
        </div>
    )
}
