import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import * as React from 'react'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

type Props = {
    options: string[]
    selection?: string[]
    onChange?: (selection: string[]) => void
    label?: string
}

export const MultiSelection: React.FC<Props> = ({
    options, selection, onChange, label
}) => {
    const saveSelectedCategories = (e: {target: {value: string | string[]}}) => {
        
        if (onChange) {
            const { value } = e.target

            if (typeof value === 'string') {
                onChange(value.split(',').map((s) => s.trim()))
            } else if (typeof value === 'object') {
                onChange(value)
            }
        }
    }

    return (
        <div>
            <FormControl fullWidth sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-name-label">{label}</InputLabel>
                <Select
                    fullWidth
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={selection}
                    multiple
                    onChange={saveSelectedCategories}
                    input={<OutlinedInput label={label} />}
                    MenuProps={MenuProps}>
                    {options.map((ct) => (
                        <MenuItem
                            key={ct}
                            value={ct}>
                            {ct}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}
