/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Box, Button, Container, FormControlLabel, FormGroup, Grid, Switch, Typography
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CancelIcon from '@material-ui/icons/Cancel'
import SaveIcon from '@material-ui/icons/Save'
import React, { useEffect } from 'react'

import { buttonStyles } from '../../styles/ButtonsStyles'
import { modalStyles } from '../../styles/ModalStyles'
import { videoLibraryContainer } from '../../styles/VideoStyles'
import { Question, QuizType, UpdateQuizType } from '../../types/types'
import { possibleQuizCategories, possibleQuizTopics } from '../../utils/filterData'
import { uploadFile } from '../../utils/services/file-services'
import { MultiSelection } from '../molecules/CategoriesSelection'
import { EditModalTemplate, EditModalTemplateProps } from '../molecules/EditModalTemplate'
import { FileUpload } from '../molecules/FileUpload'
import { GradeSelectEdit } from '../molecules/GradeSelectEdit'
import { TagComponent } from '../molecules/TagComponent'
import { TextInputTemplate } from '../molecules/TextInputTemplate'
import { QuizQuestionAndAnswersModal } from './QuizQuestionAndAnswersModal'

type Props = EditModalTemplateProps & {
    item?: QuizType
}

export const EditQuizLibraryModal: React.FC<Props> = (props) => {

    const classes = buttonStyles()
    const classesModal = modalStyles()
    const classesVideo = videoLibraryContainer()
    const [categories, setCategories] = React.useState(props.item?.categories || [])
    const [title, setTitle] = React.useState(props.item?.title || '')
    const [isPublic, setIsPublic] = React.useState(props.item?.isPublic || false)
    const [onlyOrderedResponse, setOnlyOrderedResponse] = React.useState(props.item?.onlyOrderedResponse || false)
    const [description, setDescription] = React.useState(props.item?.description || '')
    const [topics, setTopics] = React.useState(props.item?.topics || [])
    const [grades, setGrades] = React.useState(props.item?.grades || [])
    const [file, setFile] = React.useState(props.item?.file)
    const [questions, setQuestions] = React.useState(props.item?.questions || [])
    const [creatingQuestion, setCreatingQuestion] = React.useState(false)
    const [editedQuestion, setEditedQuestion] = React.useState<Question>()
    const [supplementUri, setSupplementUri] = React.useState<string[]>(props.item?.supplementUri || [])

    const canSubmit = (title.length >= 4)

    const submit = async () => {
        if (!canSubmit) {
            return
        }

        if (props.onFinish) {
            const obj: UpdateQuizType = {
                title,
                topics,
                file: file ?? null,
                categories,
                grades,
                isPublic,
                onlyOrderedResponse,
                description,
                questions,
                supplementUri
            }

            await props.onFinish(true, obj, props.isNew)
        }
    }

    const handleSaveQuestion = async (save: boolean, item: Question, isNew: boolean) => {

        if (!save || !item) {
            setCreatingQuestion(false)
            setEditedQuestion(undefined)
            return
        }

        if (isNew) {
            Object.assign(item, { questionIndex: questions.length + 1 })
            setQuestions([...questions, item])
            setCreatingQuestion(false)
        } else {
            setQuestions(
                questions.map((q) => (q === editedQuestion ? item : q))
            )
            setEditedQuestion(undefined)
        }
    }

    useEffect(() => {
        setTitle(title || '')
        setCategories(categories || [])
        setDescription(description || '')
        setTopics(topics || [])
        setFile(file || undefined)
        setIsPublic(isPublic || false)
        setOnlyOrderedResponse(onlyOrderedResponse || false)
        setQuestions(questions || [])

    }, [props.isOpen])

    return (
        <EditModalTemplate disableEnforceFocus className={classesModal.FullPage} {...props}>
            <Box style={{ width: '100%' }}>
                <Grid container className={classesVideo.container}>
                    <Grid style={{ padding: 10 }} lg={6} item>
                        <TextInputTemplate
                            fullWidth
                            underText="Indtast navnet på quizzen"
                            label="Navn"
                            value={title}
                            onChange={setTitle}
                            type="default" />
                        <FormGroup>
                            <GradeSelectEdit
                                grades={grades}
                                onChange={setGrades} />
                            <FormControlLabel
                                control={(
                                    <Switch
                                        className={classesVideo.switch}
                                        checked={!onlyOrderedResponse}
                                        onChange={() => setOnlyOrderedResponse(!onlyOrderedResponse)}
                                        size="medium"
                                        name="checkedA" />
                                )}
                                label="Svar Rækkefølge" />
                            <small className={classesVideo.smallText}>
                                Når denne er slået til kan eleverne selv bestemme rækkefølgen for svar
                            </small>
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel
                                control={(
                                    <Switch
                                        className={classesVideo.switch}
                                        checked={isPublic}
                                        onChange={() => setIsPublic(!isPublic)}
                                        size="medium"
                                        name="checkedA" />
                                )}
                                label="Offentlig quiz" />
                            <small className={classesVideo.smallText}>
                                Synlig i quizbiblioteket når knappen er slået til
                            </small>
                        </FormGroup>
                        <Grid lg={6} item>
                            <TextInputTemplate
                                fullWidth
                                underText="Giv din quizz en beskrivelse"
                                label="Kort beskrivelse"
                                value={description}
                                onChange={setDescription}
                                type="default" />
                        </Grid>
                    </Grid>

                    <Grid style={{ padding: 10 }} lg={6} item>
                        <MultiSelection
                            label="Kategori"
                            onChange={setCategories}
                            selection={categories}
                            options={possibleQuizCategories} />
                        <MultiSelection
                            label="Emne"
                            onChange={setTopics}
                            selection={topics}
                            options={possibleQuizTopics} />
                        <FileUpload
                            file={file!}
                            buttonText="Upload"
                            underText="Her kan du uploade opgaven som pdf-fil"
                            uploadFunc={uploadFile}
                            allowedType="document"
                            onChange={setFile} />
                        <TagComponent
                            underText="Tilføj flere links ved at klikke på godkend"
                            label="Eksternt link"
                            onChange={setSupplementUri}
                            tags={supplementUri} />
                    </Grid>

                </Grid>

                <Container className={classesVideo.questionContainer}>
                    <Typography variant="h5">Tilføj spørgsmål</Typography>
                    <button
                        className={classesVideo.plusButton}
                        onClick={() => setCreatingQuestion(true)}
                        style={{ fontWeight: 18 }}
                        type="button">
                        <AddIcon />
                    </button>
                </Container>
                <Container>
                    {questions.map((q) => {
                        return (

                            <button
                                className={classes.editQuestionButton}
                                onClick={() => setEditedQuestion(q)}
                                type="button">
                                <p>{q.title}</p>
                            </button>

                        )
                    })}
                </Container>
                <Container className={classesVideo.buttonContainer}>
                    <Button
                        onClick={submit}
                        variant="contained"
                        size="small"
                        disabled={props.isBusy || !canSubmit}
                        className={classes.saveButton}
                        startIcon={<SaveIcon />}>Opdater Quiz
                    </Button>
                    <Button
                        onClick={() => props.onFinish && props.onFinish(false, undefined, props.isNew)}
                        variant="contained"
                        size="small"
                        disabled={props.isBusy}
                        className={classes.cancelButton}
                        startIcon={<CancelIcon />}>
                        Annuller
                    </Button>
                </Container>
            </Box>
            <QuizQuestionAndAnswersModal
                item={editedQuestion}
                header={creatingQuestion ? 'Opret ny spørgsmål' : 'Rediger spørgsmål'}
                isOpen={!!editedQuestion || creatingQuestion}
                isNew={creatingQuestion}
                onFinish={handleSaveQuestion} />
        </EditModalTemplate>
    )
}
