/* eslint-disable */
import {
    FormControl, Input, InputLabel, Select,
} from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import React, {useEffect} from 'react'

type Props = {
    grades: number[]
    onChange?(grades: number[]): void
}

const gradeLevels = [
    'indskoling', 'mellemtrin', 'udskoling',
    '0. klasse', '1. klasse', '2. klasse',
    '3. klasse', '4. klasse', '5. klasse',
    '6. klasse', '7. klasse', '8. klasse',
    '9. klasse', '10. klasse'
]

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const gradesToOptions = (grades: number[]): string[] => {
    const opts: string[] = []
    if (grades.includes(0) &&
        grades.includes(1) &&
        grades.includes(2) &&
        grades.includes(3)) {
        opts.push(gradeLevels[0])
    }

    if (grades.includes(4) &&
        grades.includes(5) &&
        grades.includes(6)) {
        opts.push(gradeLevels[1])
    }

    if (grades.includes(7) &&
        grades.includes(8) &&
        grades.includes(9) &&
        grades.includes(10)) {
        opts.push(gradeLevels[2])
    }

    grades.map((g) => opts.push(gradeLevels[3 + g]))

    return opts
}

const optionForGrade = (grade: number) => gradeLevels[grade + 3]

export const GradeSelectEdit: React.FC<Props> = ({ grades, onChange }) => {
    const classes = useStyles()

    const [selectedOptions, setSelectedOptions] = React.useState<string[]>([])

    useEffect(() => {
        setSelectedOptions(gradesToOptions(grades))
    }, [grades])

    const handleChange = (event : any) => {
        const newSelection = new Set<string>(event.target.value)
        if (newSelection.has(gradeLevels[0]) &&
            !selectedOptions.includes(gradeLevels[0])) {
            newSelection.add(optionForGrade(0))
            newSelection.add(optionForGrade(1))
            newSelection.add(optionForGrade(2))
            newSelection.add(optionForGrade(3))
        } else if (!newSelection.has(gradeLevels[0]) &&
            selectedOptions.includes(gradeLevels[0])) {
            newSelection.delete(optionForGrade(0))
            newSelection.delete(optionForGrade(1))
            newSelection.delete(optionForGrade(2))
            newSelection.delete(optionForGrade(3))
        }

        if (newSelection.has(gradeLevels[1]) &&
            !selectedOptions.includes(gradeLevels[1])) {
            newSelection.add(optionForGrade(4))
            newSelection.add(optionForGrade(5))
            newSelection.add(optionForGrade(6))
        } else if (!newSelection.has(gradeLevels[1]) &&
            selectedOptions.includes(gradeLevels[1])) {
            newSelection.delete(optionForGrade(4))
            newSelection.delete(optionForGrade(5))
            newSelection.delete(optionForGrade(6))
        }

        if (newSelection.has(gradeLevels[2]) &&
            !selectedOptions.includes(gradeLevels[2])) {
            newSelection.add(optionForGrade(7))
            newSelection.add(optionForGrade(8))
            newSelection.add(optionForGrade(9))
            newSelection.add(optionForGrade(10))
        }else if (!newSelection.has(gradeLevels[2]) &&
            selectedOptions.includes(gradeLevels[2])) {
            newSelection.delete(optionForGrade(7))
            newSelection.delete(optionForGrade(8))
            newSelection.delete(optionForGrade(9))
            newSelection.delete(optionForGrade(10))
        }

        const ns = Array.from(newSelection)
        setSelectedOptions(ns)
        if (onChange) {
            const selectedGrades = gradeLevels.slice(3).map((gl, g) => ns.includes(gl) ? g : -1).filter((g) => g > -1)
            onChange(selectedGrades)
        }
    }

    return (
        <FormControl style={{ maxWidth: '100%' }} fullWidth className={classes.formControl}>
            <InputLabel id="grade-check-box">Grades</InputLabel>
            <Select
                fullWidth
                labelId="grade-check-box"
                id="grade-check-box"
                multiple
                value={selectedOptions}
                onChange={handleChange}
                renderValue={() => selectedOptions.join(', ')}
                input={<Input fullWidth />}
                MenuProps={MenuProps}>
                {gradeLevels.map((g: string) => (
                    <MenuItem key={g} value={g}>
                        <Checkbox checked={selectedOptions.includes(g)} />
                        <ListItemText primary={g} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
