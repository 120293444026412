import { Auth, Hub } from 'aws-amplify'
import React from 'react'

export const useCognito = (opts?: { onChange?(signedIn: boolean): void }) => {
    const [signedIn, _setSignedIn] = React.useState<boolean>(false)

    const setSignedIn = (v: boolean) => {
        if (opts?.onChange) {
            opts.onChange(v)
        }
        _setSignedIn(v)
    }

    const authListener = React.useCallback(({ payload: { event } }) => {
        console.log('new auth event: ', event)
        // eslint-disable-next-line default-case
        switch (event) {
            case 'signIn':
                setSignedIn(true)
                break
            case 'signOut':
                setSignedIn(false)
                break
        }
    }, [])

    const checkLoginStatus = async () => {
        try {
            await Auth.currentAuthenticatedUser()
            Hub.dispatch('auth', { event: 'signIn' })
        } catch (error) {
            Hub.dispatch('auth', { event: 'signOut' })
        }
    }

    React.useEffect(() => {
        Hub.listen('auth', authListener)

        checkLoginStatus().then()

        return () => Hub.remove('auth', authListener)
    }, [])

    return { signedIn }
}
