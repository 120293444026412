import { Auth } from 'aws-amplify'

const defaultRegion = 'eu-west-1'
const environments: { [T: string]: any } = {
    local: {
        refs: {
            studentSignUpUri: 'https://main.d387py34761pk4.amplifyapp.com/signup?id=',
            studentUri: 'https://main.d387py34761pk4.amplifyapp.com',
        },
        api: {
            region: defaultRegion,
            endpoint: 'http://localhost:3000/dev1'
        },
        auth: {
            userPoolId: 'eu-west-1_wVVhpzmu9',
            identityPoolId: 'eu-west-1:844117fb-eef9-4fe2-99fe-a0295501d4d6',
            userPoolWebClientId: '26iian7pqbevnh5ed7d5qvjfgg',
            region: defaultRegion
        }
    },
    development: {
        refs: {
            studentSignUpUri: 'https://main.d387py34761pk4.amplifyapp.com/signup?id=',
            studentUri: 'https://main.d387py34761pk4.amplifyapp.com',
        },
        api: {
            region: defaultRegion,
            endpoint: 'https://yas05mwp46.execute-api.eu-west-1.amazonaws.com/dev1'
        },
        auth: {
            userPoolId: 'eu-west-1_wVVhpzmu9',
            identityPoolId: 'eu-west-1:844117fb-eef9-4fe2-99fe-a0295501d4d6',
            userPoolWebClientId: '26iian7pqbevnh5ed7d5qvjfgg',
            region: defaultRegion
        }
    },
    production: {
        refs: {
            studentSignUpUri: 'https://main.d387py34761pk4.amplifyapp.com/signup?id=',
            studentUri: 'https://main.d387py34761pk4.amplifyapp.com',
        },
        api: {
            region: defaultRegion,
            endpoint: 'https://ixav5xxvra.execute-api.eu-west-1.amazonaws.com/prod'
        },
        auth: {
            userPoolId: 'eu-west-1_wVVhpzmu9',
            identityPoolId: 'eu-west-1:844117fb-eef9-4fe2-99fe-a0295501d4d6',
            userPoolWebClientId: '26iian7pqbevnh5ed7d5qvjfgg',
            region: defaultRegion
        }
    }
}

const environment = environments[process.env.NODE_ENV || 'local']
if (!environment) {
    throw Error(`Invalid or unsupported environment: ${process.env.NODE_ENV}`)
}

const jwtHeaderInjector = async () => {
    const session = await Auth.currentSession()
    if (session) {
        return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
    } else {
        return {}
    }
}

export default {
    StudentSignUpUri: environment.refs.studentSignUpUri,
    StudentUri: environment.refs.studentUri,
    Auth: {
        mandatorySignIn: true,
        ...environment.auth
    },
    API: {
        endpoints: [{
            name: 'api',
            custom_header: jwtHeaderInjector,
            ...environment.api
        }, {
            name: 'openapi',
            ...environment.api
        }]
    }
}
