import CardGiftcardIcon from '@material-ui/icons/CardGiftcard'
import * as React from 'react'
import { withRouter } from 'react-router-dom'

import { DeleteModal } from '../../components/molecules/DeleteModal'
import { EditACampaignModal } from '../../components/organisms/EditCampaignModal'
import { ManageEntitiesViewTemplate } from '../../components/organisms/ManageEntitiesViewTemplate'
import { CampaignType } from '../../types/types'
import {
    createCampaign, deleteCampaign, getCampaigns, updateCampaign
} from '../../utils/services/campaign-services'
import { fireWrap, wrap } from '../../utils/wrapper'
import { Loading } from '../Loading'

const CampaignsPage: React.FC = () => {
    const [creating, setCreating] = React.useState(false)
    const [items, setItems] = React.useState<CampaignType[]>([])
    const [editedItem, setEditedItem] = React.useState<CampaignType>()
    const [deletingItems, setDeletingItems] = React.useState<CampaignType[]>([])
    const [busy, setBusy] = React.useState(false)

    const handleDelete = async () => {
        await wrap(async () => {
            await Promise.all(
                deletingItems.map((item) => deleteCampaign(item.id))
            )

            setItems(
                items.filter((item) => !deletingItems.includes(item))
            )
            setDeletingItems([])
        }, {
            operation: 'deleting campaign(s)',
            showSuccess: true,
            stateFunc: setBusy
        })
    }

    const handleFinished = async (save: boolean, item: CampaignType, isNew: boolean) => {
        if (save && item) {
            if (isNew) {
                await wrap(async () => {
                    setItems([await createCampaign(item), ...items])
                }, {
                    operation: 'creating campaign',
                    stateFunc: setBusy,
                    showSuccess: true
                })
            } else {
                await wrap(async () => {
                    const cmp = await updateCampaign(editedItem!.id, item)
                    setItems(
                        items.map(
                            (i) => (i.id === editedItem!.id ? cmp : i)
                        )
                    )
                }, {
                    operation: 'updating campaign',
                    stateFunc: setBusy,
                    showSuccess: true
                })
            }
        }

        setEditedItem(undefined)
        setCreating(false)
    }

    React.useEffect(() => {
        fireWrap(async () => {
            const fetchedCampaigns = await getCampaigns()
            setItems(fetchedCampaigns.sort((a, b) => b.lastUpdated.localeCompare(a.lastUpdated)))
        }, {
            operation: 'loading campaigns',
            stateFunc: setBusy
        })
    }, [])
    if (busy) {
        return (
            <Loading/>
        )
    }
    return (
        <ManageEntitiesViewTemplate
            backgroundColor="#F83195"
            icon={<CardGiftcardIcon style={{ color: '#FFFFFF' }}/>}
            title="Kampagner"
            items={items}
            headers={['Beskrivelse', 'Link', 'Udgivet']}
            convertItem={(a: CampaignType) => [a.description, a.link, a.published ? 'Ja' : 'Nej']}
            canCreate
            canEdit
            canDelete
            onCreate={() => setCreating(true)}
            onDelete={setDeletingItems}
            onEdit={setEditedItem}>
            <DeleteModal
                onConfirmAction={handleDelete}
                onCancelAction={() => setDeletingItems([])}
                confirmActionButtonText="Ja, helt sikker"
                cancelActionButtonText="Nej"
                headerText="Er du sikker på at du vil slette denne kamagne?"
                isOpen={deletingItems.length > 0}
                onClose={() => setDeletingItems([])}/>
            <EditACampaignModal
                isBusy={busy}
                key={editedItem?.id}
                header={editedItem ? 'Rediger Kampagne' : 'Opret Kampagne'}
                isOpen={!!editedItem || creating}
                isNew={creating}
                offerLink={editedItem?.link}
                description={editedItem?.description}
                published={editedItem?.published}
                image={editedItem?.picture}
                onFinish={handleFinished}/>
        </ManageEntitiesViewTemplate>
    )
}
export default withRouter(CampaignsPage)
