import { API } from 'aws-amplify'

import { UpdateVideoType, VideoType, VimeoVideoType } from '../../types/types'

export const validateVimeoVideo = async (id: string): Promise<VimeoVideoType | undefined> => {
    return API.get('api', `/videos/vimeo/${id}`, {})
}

export const createVideo = async (params: UpdateVideoType): Promise<VideoType> => {
    return API.post('api', '/videos', {
        body: params
    })
}

export const getVideoById = async (videoId: string): Promise<VideoType> => {
    return API.get('api', `/videos/${videoId}`, {})
}

export const getVideos = async (): Promise<VideoType[]> => {
    return API.get('api', '/videos', {})
}

export const updateVideo = async (videoId: string, params: UpdateVideoType):
    Promise<VideoType> => {
    return API.put('api', `/videos/${videoId}`, {
        body: params
    })
}

export const deleteVideo = async (videoId: string): Promise<boolean> => {
    return API.del('api', `/videos/${videoId}`, {})
}
