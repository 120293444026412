import { Container } from '@material-ui/core'
import * as React from 'react'

import { white } from '../../utils/colors'

interface Props {
}

export const ToolBarContainer: React.FC<Props> = ({ children }) => {

    return (
        <Container
            style={{
                maxWidth: '100%',
                padding: 5,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: white,
                borderBottomColor: '#ddd',
                borderBottomWidth: 2,
                borderBottomStyle: 'solid'
            }}>
            <div
                style={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                {children}
            </div>
        </Container>
    )
}
