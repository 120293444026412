/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography } from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import { Line } from 'react-chartjs-2'

import { DataPoint } from '../../types/types'

type Props = {
    totalTeacherCount: number
    teacherRegistrationsByDate: DataPoint[]
}

const options = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
}

export const TeacherStatisticsView: React.FC<Props> = ({ totalTeacherCount, teacherRegistrationsByDate }) => {

    const data = {
        labels: teacherRegistrationsByDate.map((i) => moment.utc(i.date).format('MM/YY')),
        datasets: [
            {
                label: 'Antal Lærerprofiler Profiler',
                data: teacherRegistrationsByDate.map((i) => i.count),
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
            },
        ],
    }

    return (
        <>
            <div className="header">
                <Typography style={{ textAlign: 'center' }} variant="h4">Lærerprofiler</Typography>
                <Typography
                    style={{ textAlign: 'center' }}
                    variant="h6">
                    Antal Profiler: {totalTeacherCount}
                </Typography>
            </div>
            <Line style={{ maxHeight: 300 }} type={Line} data={data} options={options}/>
        </>
    )
}
