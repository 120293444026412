import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import * as React from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

import { DeleteModal } from '../../components/molecules/DeleteModal'
import { ToggleTemplate } from '../../components/molecules/ToggleTemplate'
import { EditPDFLibraryModal } from '../../components/organisms/EditPDFLibraryModal'
import { ManageEntitiesViewTemplate } from '../../components/organisms/ManageEntitiesViewTemplate'
import { pdfStyles } from '../../styles/PDFStyles'
import { PDFType, UpdatePDFType } from '../../types/types'
import { sortItemsByCreatedAtString } from '../../utils/helper'
import {
    createPDF, deletePDF, getPDFs, updatePDF
} from '../../utils/services/pdf-library-services'
import { fireWrap, wrap } from '../../utils/wrapper'
import { Loading } from '../Loading'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const filterPDFLibrary = (filter: string, item: PDFType) => {
    return !!item.title?.toLowerCase().includes(filter.toLowerCase())
        || !!item.mainFile?.name?.toLowerCase().includes(filter.toLowerCase())
        || !!item.supplementUri?.map((a) => a.toLowerCase().includes(filter.toLowerCase()))
        || !!item.resultsFile?.name?.toLowerCase().includes(filter.toLowerCase())
}

export const PDFLibraryPage: React.FC = () => {
    const [creating, setCreating] = React.useState(false)
    const [items, setItems] = React.useState<PDFType[]>([])
    const [editedItem, setEditedItem] = React.useState<PDFType>()
    const [deletingItems, setDeletingItems] = React.useState<PDFType[]>([])
    const [busy, setBusy] = React.useState(false)

    const classes = pdfStyles()

    const convertItem = (v: PDFType) => {
        return [
            <Document file={v.mainFile?.uri}>
                <Page pageIndex={0} height={210} />
            </Document>,
            <p key={0} style={{ maxWidth: '80%' }}>{v.title}</p>,
            <p key={0} style={{ maxWidth: '80%' }}>{v.downloadedTimes || 0}</p>,
            <div key={1}>
                {v.categories?.map((c) => {
                    return (
                        <p key={c}>{c}</p>
                    )
                })}
            </div>,
            <p key={2}>{v.fact}</p>,
            <p key={3}>{v.description}</p>,
            <div key={4}>
                {v.topics?.map((t) => {
                    return (
                        <p key={t} className={classes.tag}>{t}</p>
                    )
                })}
            </div>,
            <p key={5}>{v.mainFile?.name}</p>,
            <div key={6}>
                <ToggleTemplate />
            </div>
        ]
    }

    const handleDelete = async () => {
        await wrap(async () => {
            await Promise.all(
                deletingItems.map((item) => deletePDF(item.id))
            )

            setItems(
                items.filter((item) => !deletingItems.includes(item))
            )

            setDeletingItems([])
        }, {
            operation: 'Deleting PDF(s)',
            showSuccess: true,
            stateFunc: setBusy
        })
    }

    const handleFinished = async (save: boolean, item: UpdatePDFType, isNew: boolean) => {
        if (save && item) {
            await wrap(async () => {
                if (isNew) {
                    setItems([await createPDF(item), ...items])
                } else {
                    const updated = await updatePDF(editedItem!.id, item)
                    setItems(
                        items.map(
                            (i) => (i.id === editedItem!.id ? updated : i)
                        )
                    )
                }
            }, {
                operation: isNew ? 'Creating PDF' : 'Updating PDF',
                showSuccess: true,
                stateFunc: setBusy
            })
        }

        setEditedItem(undefined)
        setCreating(false)
    }

    React.useEffect(() => {
        fireWrap(async () => {
            const PDFs = await getPDFs()
            setItems(sortItemsByCreatedAtString(PDFs))
        }, {
            operation: 'Loading Pdfs',
            stateFunc: setBusy
        })
    }, [])

    if (busy) {
        return (
            <Loading />
        )
    }
    return (
        <ManageEntitiesViewTemplate
            searchComponent
            backgroundColor="#278753"
            icon={<PictureAsPdfIcon style={{ color: '#FFFFFF' }} />}
            title="PDFbibliotek"
            items={items}
            filterItem={filterPDFLibrary}
            headers={['  ',
                'Titel',
                'Mængde af downloads',
                'Kategori',
                'Facit',
                'Beskrivelse',
                'Emne',
                'Filer',
                'Offentlig']}
            convertItem={convertItem}
            canCreate
            canEdit
            canDelete
            onCreate={() => setCreating(true)}
            onDelete={setDeletingItems}
            onEdit={setEditedItem}>
            <DeleteModal
                onConfirmAction={handleDelete}
                onCancelAction={() => setDeletingItems([])}
                confirmActionButtonText="Ja, helt sikker"
                cancelActionButtonText="Nej"
                headerText="Er du sikker på at du vil slette denne kamagne?"
                isOpen={deletingItems.length > 0}
                onClose={() => setDeletingItems([])} />
            <EditPDFLibraryModal
                item={editedItem}
                isBusy={busy}
                key={editedItem?.id}
                header={editedItem ? 'Rediger PDF' : 'Opret PDF'}
                isOpen={!!editedItem || creating}
                isNew={creating}
                onFinish={handleFinished} />
        </ManageEntitiesViewTemplate>
    )
}
