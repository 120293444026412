import {
    FormControl, FormControlLabel, FormGroup, Switch
} from '@material-ui/core'
import React from 'react'

import { lightGrey } from '../../utils/colors'

type Props = {

    label?: string;
    underText?: string;
    onChange?: (value: any) => void;
    onSubmit?(value: String): void;
    value?: any;
}
export const ToggleTemplate: React.FC<Props> = ({
    label,
    underText,
    onChange,
    onSubmit,
    value
}) => {
    return (
        <>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                marginBottom: 20,
                marginTop: 20
            }}>
                <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                        <FormControlLabel
                            style={{
                                margin: 0,
                                display: 'flex',
                                justifyContent:
                                    'flex-start',
                                alignItems: 'flex-start',
                            }}
                            label={label}
                            value={value}
                            onChange={() => onChange}
                            onSubmit={() => onSubmit}
                            control={<Switch style={{ color: '#28a745' }} />}
                            labelPlacement="top"/>
                    </FormGroup>
                </FormControl>
                <small style={{ paddingTop: 5, fontWeight: 300, color: lightGrey }}>{underText}</small>
            </div>
        </>

    )
}
