/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography } from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import { Line } from 'react-chartjs-2'

import { DataPoint } from '../../types/types'

type Props = {
    totalHomeworkCount: number
    homeworksCreatedByDate: DataPoint[]
}

const options = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
}

export const HomeworkStatisticsView: React.FC<Props> = ({ totalHomeworkCount, homeworksCreatedByDate }) => {

    const data = {
        labels: homeworksCreatedByDate.map((i) => moment.utc(i.date).format('MM/YY')),
        datasets: [
            {
                label: 'Antal Lektielinks',
                data: homeworksCreatedByDate.map((i) => i.count),
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
            },
        ],
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
                <Typography style={{ textAlign: 'center' }} variant="h5">Lektielinks</Typography>
                <Typography
                    style={{ textAlign: 'center' }}
                    variant="h6">
                    Antal Lektielinks:{totalHomeworkCount}
                </Typography>
            </div>
            <Line style={{ maxHeight: 300, maxWidth: 600 }} type={Line} data={data} options={options}/>
        </div>
    )
}
