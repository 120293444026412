import { API } from 'aws-amplify'

import { PDFType, UpdatePDFType } from '../../types/types'

export const createPDF = async (params: UpdatePDFType): Promise<PDFType> => {
    return API.post('api', '/pdf', {
        body: params
    })
}

export const getPDFById = async (pdfId: string): Promise<PDFType> => {
    return API.get('api', `/pdf/${pdfId}`, {})
}

export const getPDFs = async (): Promise<PDFType[]> => {
    return API.get('api', '/pdf', {})
}

export const updatePDF = async (pdfId: string, params: UpdatePDFType):
    Promise<PDFType> => {
    return API.put('api', `/pdf/${pdfId}`, {
        body: params
    })
}

export const deletePDF = async (pdfId: string): Promise<boolean> => {
    return API.del('api', `/pdf/${pdfId}`, {})
}
