export const possibleVideoTopics = [
    'Geometri',
    'Trigonometri',
    'Økonomi',
    'Talforståelse',
    '10 ́ervenner',
    'Brøker',
    'Procent',
    'Decimalttal',
    'Algebra',
    'Ligninger',
    'Funktioner',
    'Koordinatsystemet',
    'Grafer og tabeller',
    'Måling',
    'Statistik',
    'Sandsynlighed',
    'Kombinatorik',
    'Modellering',
    'Hjælpemidler',
    'Kommunikation',
    'Problembehandling',
    'Ræsonnement',
    'Repræsentation og Symbolbehandling',
    'Tid & Hastighed',
    'Omregning af enheder',
    'Addition',
    'Subtraktion',
    'Multiplikation',
    'Division'
]
export const possiblePDFTopics = [
    'Geometri',
    'Trigonometri',
    'Økonomi',
    'Talforståelse',
    '10 ́ervenner',
    'Brøker',
    'Procent',
    'Decimalttal',
    'Algebra',
    'Ligninger',
    'Funktioner',
    'Koordinatsystemet',
    'Grafer og tabeller',
    'Måling',
    'Statistik',
    'Sandsynlighed',
    'Kombinatorik',
    'Modellering',
    'Hjælpemidler',
    'Kommunikation',
    'Problembehandling',
    'Ræsonnement',
    'Repræsentation og Symbolbehandling',
    'Tid & Hastighed',
    'Omregning af enheder',
    'Addition',
    'Subtraktion',
    'Multiplikation',
    'Division'
]
export const possibleQuizTopics = [
    'Geometri',
    'Trigonometri',
    'Økonomi',
    'Talforståelse',
    '10 ́er-venner',
    'Brøker',
    'Procent',
    'Decimaltal',
    'Algebra',
    'Ligninger',
    'Funktioner',
    'Koordinatsystemet',
    'Grafer og tabeller',
    'Måling',
    'Statistik',
    'Sandsynlighed',
    'Kombinatorik',
    'Blandede opgaver',
    'Tæl & Tal',
    'Omregning af enheder',
    'Addition',
    'Subtraktion',
    'Multiplikation',
    'Division'
]

export const possibleVideoCategories = [
    'Videopgave',
    'Spil',
    'Hjernevrider',
    'Praktisk opgave',
    'Mundtlig oplæg',
    'Intro-video',
    'Bevægelse',
    'Undersøgende matematik',
    'Hygge'
]

export const possibleQuizCategories = [
    'Escape room',
    'Matematikmission',
    'Emne-Quiz',
    'Læringsquiz',
    'Åben quiz',
    'Lukket quiz',
]

export const possiblePDFCategories = [
    'Træningsopgaver',
    'Tekstopgaver',
    'Ræsonnementsopgaver',
    'Spil',
    'Mundtlige oplæg',
    'Undersøgende opgaver',
    'Hygge',
    'Praktiske opgaver',
    'Farveopgaver',
    'Årstidsopgaver',
    'Tegn, byg og design',
    'Regnestrategier',
    'Plakater m.m.',
    'Åbne opgaver',
    'Escape Rooms-opgaver',
    'Bevægelsesopgaver',
    'Stjerneløb'
]
