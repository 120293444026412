export const sortItemsByCreatedAtString = (array: any[]) => {
    // eslint-disable-next-line no-nested-ternary
    return array.sort((a, b) => (a.createdAt ? (a.createdAt < b.createdAt ? 1 : -1) : 1))
}
export function isValidHttpUrl(inputUrl: string) {
    let url
    
    try {
        url = new URL(inputUrl)
    } catch (_) {
        return false  
    }
  
    return url.protocol === 'http:' || url.protocol === 'https:'
}
