import DateFnsUtils from '@date-io/date-fns'
import { Button, Container } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import SaveIcon from '@material-ui/icons/Save'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import React from 'react'

import { buttonStyles } from '../../styles/ButtonsStyles'
import { modalStyles } from '../../styles/ModalStyles'
import { EditModalTemplate, EditModalTemplateProps } from '../molecules/EditModalTemplate'
import { TextInputTemplate } from '../molecules/TextInputTemplate'

type Props = EditModalTemplateProps & {
    name?: string,
    uniLogId?: number,
    createdAt: Date
}

export const EditSchoolModal: React.FC<Props> = (props) => {
    const classes = buttonStyles()

    const [name, setName] = React.useState(props.name)
    const [schoolUniLogId, setSchoolUniLogId] = React.useState<string>(props.uniLogId?.toString() || '')

    const [createdAt, setCreatedAt] = React.useState<Date | null>(new Date())
    const classesModal = modalStyles()

    const nameError = !name || name.length < 2 || name.length > 50
    const uniLogIdError = Number.isNaN(Number(schoolUniLogId)) || schoolUniLogId.length === 0
    const error = nameError || uniLogIdError

    const submit = async () => {
        if (error) {
            return
        }

        if (props.onFinish) {
            const obj = {
                name,
                uniLogId: schoolUniLogId,
                expires: moment(createdAt).format('DD/MM/YYYY')
            }

            await props.onFinish(true, obj, props.isNew)
        }
    }

    return (
        <EditModalTemplate
            className={classesModal.paper}
            {...props}>
            <TextInputTemplate
                error={nameError}
                underText="Navn på skolen"
                label="Navn"
                value={name}
                onChange={setName}
                type="default"/>
            <TextInputTemplate
                error={uniLogIdError}
                underText="Instid for skolen"
                label="InstId"
                value={schoolUniLogId}
                onChange={setSchoolUniLogId}
                type="default"/>
            <small>Indtast udløbsdatoen</small>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    fullWidth
                    margin="normal"
                    id="date-picker-dialog"
                    format="MM/dd/yyyy"
                    value={createdAt?.getTime()}
                    onChange={setCreatedAt}
                    KeyboardButtonProps={{ 'aria-label': 'change date' }}/>
            </MuiPickersUtilsProvider>

            <Container className={classes.buttonsWrapper}>
                <Button
                    onClick={submit}
                    variant="contained"
                    size="small"
                    disabled={props.isBusy || error}
                    className={classes.saveButton}
                    startIcon={<SaveIcon />}>Opdater
                </Button>
                <Button
                    onClick={() => props.onFinish && props.onFinish(false, undefined, props.isNew)}
                    variant="contained"
                    size="small"
                    disabled={props.isBusy || error}
                    className={classes.cancelButton}
                    startIcon={<CancelIcon/>}>
                    Annuller
                </Button>
            </Container>

        </EditModalTemplate>
    )
}
