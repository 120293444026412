import React from 'react'

import HomeImage from '../../assets/homeImage.png'

export const BackgroundWithImage: React.FC = ({ children }) => {
    return (
        <div style={{
            minWidth: '100%',
            display: 'flex',
            height: '100%',
            minHeight: '100vh',
            flexDirection: 'column',
        }}>
            {children}
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                <img
                    style={{
                        maxWidth: 300, position: 'absolute', bottom: 5, zIndex: 0
                    }}
                    src={HomeImage}
                    alt="People and numbers" />
            </div>
        </div>
    )
}
