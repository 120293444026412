import { API } from 'aws-amplify'

import { QuizType, UpdateQuizType } from '../../types/types'

export const filterQuizLibrary = (filter: string, item: QuizType) => {
    const areTopicsIncluded = item.topics.findIndex((a) => a.toLowerCase().includes(filter.toLowerCase().trim()))
    return item.title.toLowerCase().includes(filter.toLowerCase().trim()) || areTopicsIncluded >= 0
}

export const createQuiz = async (params: UpdateQuizType): Promise<QuizType> => {
    return API.post('api', '/quizzes', {
        body: params
    })
}

export const getQuizById = async (quizId: string): Promise<QuizType> => {
    return API.get('api', `/quizzes/${quizId}`, {})
}

export const getQuizzes = async (): Promise<QuizType[]> => {
    return API.get('api', '/quizzes', {})
}

export const updateQuiz = async (quizId: string, params: UpdateQuizType):
    Promise<QuizType> => {
    return API.put('api', `/quizzes/${quizId}`, {
        body: params
    })
}

export const deleteQuiz = async (quizId: string): Promise<boolean> => {
    return API.del('api', `/quizzes/${quizId}`, {})
}
