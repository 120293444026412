/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Box, Button, Container, FormControlLabel, FormGroup, Grid, Switch
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import SaveIcon from '@material-ui/icons/Save'
import React, { useEffect } from 'react'

import { buttonStyles } from '../../styles/ButtonsStyles'
import { modalStyles } from '../../styles/ModalStyles'
import { videoLibraryContainer } from '../../styles/VideoStyles'
import { UpdateVideoType, VideoType, VimeoVideoType } from '../../types/types'
import { possibleVideoCategories, possibleVideoTopics } from '../../utils/filterData'
import { uploadFile } from '../../utils/services/file-services'
import { MultiSelection } from '../molecules/CategoriesSelection'
import { EditModalTemplate, EditModalTemplateProps } from '../molecules/EditModalTemplate'
import { FileUpload } from '../molecules/FileUpload'
import { GradeSelectEdit } from '../molecules/GradeSelectEdit'
import { TagComponent } from '../molecules/TagComponent'
import { TextInputTemplate } from '../molecules/TextInputTemplate'
import { VimeoVideoInput } from '../molecules/VimeoVideoInput'

type Props = EditModalTemplateProps & {
    item?: VideoType
}

export const EditVideoLibraryModal: React.FC<Props> = (props) => {

    const classes = buttonStyles()
    const classesModal = modalStyles()
    const classesVideo = videoLibraryContainer()

    const [categories, setCategories] = React.useState(props.item?.categories || [])
    const [title, setTitle] = React.useState(props.item?.title || '')
    const [videoPublic, setVideoPublic] = React.useState(props.item?.isPublic || false)
    const [fact, setFact] = React.useState(props.item?.fact || '')
    const [supplementUri, setSupplementUri] = React.useState(props.item?.supplementUri || [])
    const [description, setDescription] = React.useState(props.item?.description || '')

    const [topics, setTopics] = React.useState(props.item?.topics || [])
    const [grades, setGrades] = React.useState(props.item?.grades || [])

    const [mainFile, setMainFile] = React.useState(props.item?.mainFile)
    const [resultsFile, setResultsFile] = React.useState(props.item?.resultsFile)

    const [vimeoVideoDetails, setVimeoVideoDetails] = React.useState<VimeoVideoType | undefined>(
        props.item?.vimeoVideoId
            ? {
                title: props.item.title,
                id: props.item.vimeoVideoId,
                thumbnailUri: props.item.thumbnailUri
            } : undefined
    )
    
    const canSubmit = (
        vimeoVideoDetails
        && title.length >= 10
    )

    const submit = async () => {
        if (!canSubmit || !vimeoVideoDetails) {
            return
        }

        if (props.onFinish) {
            const obj: UpdateVideoType = {
                title,
                description,
                topics,
                grades,
                categories,
                vimeoVideoId: vimeoVideoDetails.id,
                isPublic: videoPublic,
                supplementUri,
                fact,
                mainFile,
                resultsFile
            }

            await props.onFinish(true, obj, props.isNew)
        }
    }

    const newProps = {
        ...props,
        isBusy: props.isBusy
    }

    useEffect(() => {
        setDescription(description || '')
        setCategories(categories || [])
        setFact(fact || '')
        setTopics(topics || [])
        setGrades(grades || [])
        setMainFile(mainFile || undefined)
        setResultsFile(resultsFile || undefined)
        setSupplementUri(supplementUri || [])
        setVideoPublic(videoPublic || false)
    }, [props.isOpen])

    return (
        <EditModalTemplate disableEnforceFocus className={classesModal.FullPage} {...newProps}>
            <Box style={{ width: '100%' }}>
                <Grid container className={classesVideo.container}>
                    <Grid style={{ padding: 10 }} lg={6} item>
                        <VimeoVideoInput
                            vimeoVideoId={vimeoVideoDetails?.id}
                            onChange={setVimeoVideoDetails}/>
                        <MultiSelection
                            label="Kategori"
                            onChange={setCategories}
                            selection={categories}
                            options={possibleVideoCategories}/>
                        <TextInputTemplate
                            fullWidth
                            underText="Giv din video en titel"
                            label="Titel"
                            value={title}
                            onChange={setTitle}
                            type="default"/>
                        <TextInputTemplate
                            fullWidth
                            underText="Giv din video en kort beskrivelse"
                            label="Beskrivelse"
                            value={description}
                            onChange={setDescription}
                            type="default"/>
                        <FormGroup>
                            <FormControlLabel
                                control={(
                                    <Switch
                                        className={classesVideo.switch}
                                        checked={videoPublic}
                                        onChange={() => setVideoPublic(!videoPublic)}
                                        size="medium"
                                        name="checkedA" />
                                )}
                                label="Offentlig video"/>
                            <small className={classesVideo.smallText}>
                                Når denne er slået til, vil videoen være tilgængelig uden at logge ind
                            </small>
                        </FormGroup>
                        <MultiSelection
                            label="Emne"
                            onChange={setTopics}
                            selection={topics}
                            options={possibleVideoTopics}/>
                    </Grid>
                    <Grid lg={6} item container className={classesVideo.formControl}>
                        <Grid style={{ paddingRight: 10 }} item>
                            <Grid style={{ padding: 10 }} item>
                                <FileUpload
                                    file={mainFile}
                                    buttonText="Uploade"
                                    underText="Her kan du uploade opgaven som pdf-fil"
                                    uploadFunc={uploadFile}
                                    allowedType="document"
                                    onChange={setMainFile}/>
                                <GradeSelectEdit
                                    grades={grades}
                                    onChange={setGrades}/>
                                <FileUpload
                                    file={resultsFile}
                                    buttonText="Upload facit fil"
                                    underText="Her kan du uploade opgaven som pdf-fil"
                                    uploadFunc={uploadFile}
                                    allowedType="document"
                                    onChange={setResultsFile}/>
                            </Grid>

                            <Grid item>
                                <TextInputTemplate
                                    fullWidth
                                    underText="Her kan du angive facit som tekst"
                                    label="Angiv facit tekst"
                                    value={fact}
                                    onChange={setFact}
                                    type="default"/>
                                <TagComponent
                                    underText="Tilføj flere links ved at klikke på godkend"
                                    label="Eksternt link"
                                    onChange={setSupplementUri}
                                    tags={supplementUri}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Container className={classesVideo.buttonContainer}>
                    <Button
                        onClick={submit}
                        variant="contained"
                        size="small"
                        disabled={props.isBusy || !canSubmit}
                        className={classes.saveButton}
                        startIcon={<SaveIcon />}>Opdater Video
                    </Button>
                    <Button
                        onClick={() => props.onFinish && props.onFinish(false, undefined, props.isNew)}
                        variant="contained"
                        size="small"
                        disabled={props.isBusy}
                        className={classes.cancelButton}
                        startIcon={<CancelIcon/>}>
                        Annuller
                    </Button>
                </Container>
            </Box>
        </EditModalTemplate>
    )
}
