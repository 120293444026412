import {
    Box, Button, Container, Grid, Typography
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import SaveIcon from '@material-ui/icons/Save'
import React, { useEffect } from 'react'

import { buttonStyles } from '../../styles/ButtonsStyles'
import { modalStyles } from '../../styles/ModalStyles'
import { videoLibraryContainer, videoStyles } from '../../styles/VideoStyles'
import {
    CommunityQuizType, Question, UpdateCommunityQuizType
} from '../../types/types'
import { MultiSelection } from '../molecules/CategoriesSelection'
import { EditModalTemplate, EditModalTemplateProps } from '../molecules/EditModalTemplate'
import { TextInputTemplate } from '../molecules/TextInputTemplate'
import { QuizQuestionAndAnswersModal } from './QuizQuestionAndAnswersModal'

type Props = EditModalTemplateProps & {
    item?: CommunityQuizType
}

const possibleFields = {
    title: 'titel',
    description: 'beskrivelse',
    categories: 'kategorier',
    topics: 'emne',
    grades: 'klassetrin',
    file: 'fil',
    questions: 'spørgsmål'
}
export const EditCommunityQuizModal: React.FC<Props> = (props) => {

    const classes = buttonStyles()
    const videoClassesStyles = videoStyles()
    const classesModal = modalStyles()
    const classesVideo = videoLibraryContainer()
    const classes1 = buttonStyles()

    const [notApprovedFields, setNotApprovedFields] = React.useState<any[]>([])
    const [feedbackNote, setFeedbackNote] = React.useState<string>('')
    const [categories, setCategories] = React.useState(props.item?.categories || [])
    const [isPublic, setIsPublic] = React.useState(props.item?.isPublic || false)
    // const [onlyOrderedResponse, setOnlyOrderedResponse] = React.useState(props.item?.onlyOrderedResponse || false)
    // const [grades, setGrades] = React.useState(props.item?.grades || [])
    const [topics, setTopics] = React.useState(props.item?.topics || [])
    const [file, setFile] = React.useState(props.item?.file)
    const [questions, setQuestions] = React.useState(props.item?.questions || [])
    const [creatingQuestion, setCreatingQuestion] = React.useState(false)
    const [editedQuestion, setEditedQuestion] = React.useState<Question>()

    const canSubmit = (notApprovedFields.length > 0)

    function getKeyByValue(object: any, value: string) {
        return Object.keys(object).find((key) => object[key] === value)
    }

    const submit = async () => {
        if (!canSubmit) {
            return
        }

        if (props.onFinish) {
            notApprovedFields.map((field, index) => {
                const newValue = getKeyByValue(possibleFields, field)
                return notApprovedFields.splice(index, 1, newValue)
            })
            const obj: UpdateCommunityQuizType = {
                ...props.item,
                notApprovedFields,
                feedbackNote,
            }

            await props.onFinish(true, obj, false)
        }
    }

    const handleSaveQuestion = async (save: boolean, item: Question) => {

        if (!save || !item) {
            setCreatingQuestion(false)
            setEditedQuestion(undefined)
            return
        }

        Object.assign(item, { questionIndex: questions.length + 1 })

        setQuestions(
            questions.map((q) => (q === editedQuestion ? item : q))
        )
        setEditedQuestion(undefined)

    }

    useEffect(() => {
        setCategories(categories || [])
        setTopics(topics || [])
        setFile(file || undefined)
        setIsPublic(isPublic || false)
        // setOnlyOrderedResponse(onlyOrderedResponse || false)
        setQuestions(questions || [])

    }, [props.isOpen])

    // TODO: when you click a button to edit a question do "setEditedQuestion(question)"
    return (
        <EditModalTemplate
            disableEnforceFocus
            {...props}
            className={classesModal.FullPage}
            header="Opdater quiz status">
            <Box style={{ width: '100%' }}>
                {props.item && (
                    <Grid container className={classesVideo.container}>
                        <Grid style={{ padding: 10 }} lg={6} item>
                            <TextInputTemplate
                                fullWidth
                                disabled
                                label="Navn"
                                value={props.item.title}
                                type="default" />
                            <TextInputTemplate
                                fullWidth
                                disabled
                                label="Kort beskrivelse"
                                value={props.item.description}
                                type="default" />
                            {props.item.file && (
                                <Button
                                    className={classes1.uploadButton}
                                    fullWidth
                                    href={props.item.file.uri}
                                    variant="outlined">
                                    Se fil
                                </Button>
                            )}
                            <Grid lg={6} item>
                                {questions.map((q) => {
                                    return (
                                        <button
                                            className={classes.editQuestionButton}
                                            onClick={() => setEditedQuestion(q)}
                                            type="button">
                                            <p>{q.title}</p>
                                        </button>
                                    )
                                })}

                            </Grid>
                        </Grid>

                        <Grid style={{ padding: 10 }} lg={6} item>
                            <Box className={classesVideo.arrayTagContainer}>
                                <Typography>Kategori</Typography>
                                {props.item.categories && props.item.categories.map((t) => {
                                    return (
                                        <p className={videoClassesStyles.tag}>{t}</p>
                                    )
                                })}
                            </Box>
                            <Box className={classesVideo.arrayTagContainer}>
                                <Typography>Emne</Typography>
                                {props.item.topics && props.item.topics.map((t) => {
                                    return (
                                        <p className={videoClassesStyles.tag}>{t}</p>
                                    )
                                })}
                            </Box>
                            <Box className={classesVideo.arrayTagContainer}>
                                <Typography>Klassetrin</Typography>
                                {props.item.grades && props.item.grades.map((t) => {
                                    return (
                                        <p className={videoClassesStyles.tag}>{t}</p>
                                    )
                                })}
                            </Box>

                        </Grid>
                    </Grid>
                )}
                <Container>
                    <MultiSelection
                        label="Afviste felter"
                        onChange={setNotApprovedFields}
                        selection={notApprovedFields}
                        options={Object.values(possibleFields)} />
                    <TextInputTemplate
                        underText="Bemærk for læreren, der forklarer, hvorfor quizzen blev afvist. "
                        label="Lærer feedback"
                        value={feedbackNote}
                        disabled={props.isBusy}
                        onChange={(target) => setFeedbackNote(target)} />
                </Container>
                <Container className={classesVideo.buttonContainer}>
                    <Button
                        onClick={submit}
                        variant="contained"
                        size="small"
                        disabled={props.isBusy || !canSubmit}
                        className={classes.saveButton}
                        startIcon={<SaveIcon />}>Opdater Quiz
                    </Button>
                    <Button
                        onClick={() => props.onFinish && props.onFinish(false, undefined, false)}
                        variant="contained"
                        size="small"
                        disabled={props.isBusy}
                        className={classes.cancelButton}
                        startIcon={<CancelIcon />}>
                        Annuller
                    </Button>
                </Container>
            </Box>
            <QuizQuestionAndAnswersModal
                viewOnly
                item={editedQuestion}
                header={creatingQuestion ? 'Opret ny spørgsmål' : 'Rediger spørgsmål'}
                isOpen={!!editedQuestion || creatingQuestion}
                isNew={creatingQuestion}
                onFinish={handleSaveQuestion} />
        </EditModalTemplate>
    )
}
