import { Button, Container } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import SaveIcon from '@material-ui/icons/Save'
import React, { useEffect } from 'react'

import { buttonStyles } from '../../styles/ButtonsStyles'
import { modalStyles } from '../../styles/ModalStyles'
import { EditModalTemplate, EditModalTemplateProps } from '../molecules/EditModalTemplate'
import { TextInputTemplate } from '../molecules/TextInputTemplate'

type Props = EditModalTemplateProps & {
    price?: number;
    amountOfMonths?: number;
 }

export const EditPriceModal: React.FC<Props> = (props) => {
    const classes = buttonStyles()
    const classesModal = modalStyles()

    const [price, setPrice] = React.useState<string>('')
    const [amountOfMonths, setAmountOfMonths] = React.useState<string>('')

    const priceError = Number.isNaN(Number(price)) || price.length === 0
    const durationError = Number.isNaN(Number(amountOfMonths)) || amountOfMonths.length === 0

    const error = priceError || durationError

    useEffect(() => {
        setPrice(props?.price?.toString() || '')
        setAmountOfMonths(props?.amountOfMonths?.toString() || '')
    }, [props.isOpen])

    const submit = async () => {
        if (error) {
            return
        }

        if (props.onFinish) {
            const obj = {
                price,
                amountOfMonths
            }

            await props.onFinish(true, obj, props.isNew)
        }
    }

    return (
        <EditModalTemplate 
            className={classesModal.paper}
            header="Redigér pris"
            {...props}>
            <TextInputTemplate
                error={priceError}
                underText="Indtast en pris for brugeradgang i DKK"
                label="Pris"
                value={price}
                onChange={setPrice}
                type="default"/>
            <TextInputTemplate
                error={durationError}
                underText="Indtast antallet af måneder som adgangen skal gælde"
                label="Måneder"
                value={amountOfMonths}
                onChange={setAmountOfMonths}
                type="default"/>

            <Container className={classes.buttonsWrapper}>
                <Button
                    onClick={submit}
                    variant="contained"
                    size="small"
                    disabled={props.isBusy || error}
                    className={classes.saveButton}
                    startIcon={<SaveIcon />}>Opdater
                </Button>
                <Button
                    onClick={() => props.onFinish && props.onFinish(false, undefined, props.isNew)}
                    variant="contained"
                    size="small"
                    disabled={props.isBusy || error}
                    className={classes.cancelButton}
                    startIcon={<CancelIcon/>}>
                    Annuller
                </Button>
            </Container>

        </EditModalTemplate>
    )
}
