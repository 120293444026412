import { API } from 'aws-amplify'

import { SoundType, UpdateSoundType } from '../../types/types'

export const createSound = async (params: UpdateSoundType): Promise<SoundType> => {
    return API.post('api', '/sounds', {
        body: params
    })
}

export const getSoundById = async (soundId: string): Promise<SoundType> => {
    return API.get('api', `/sounds/${soundId}`, {})
}

export const getSounds = async (): Promise<SoundType[]> => {
    return API.get('api', '/sounds', {})
}

export const updateSound = async (soundId: string, params: UpdateSoundType):
    Promise<SoundType> => {
    return API.put('api', `/sounds/${soundId}`, {
        body: params
    })
}

export const deleteSound = async (soundId: string): Promise<boolean> => {
    return API.del('api', `/sounds/${soundId}`, {})
}
