import { API } from 'aws-amplify'

import { StudentType } from '../../types/types'

export const getStudents = async (): Promise<StudentType[]> => {
    return API.get('api', '/students', {})
}

export const deleteStudentUser = async (studentId: string): Promise<void> => {
    return API.del('api', `/student/${studentId}`, {})
}

export const resetStudentPassword = async (studentId: string): Promise<void> => {
    return API.post('api', `/students/${studentId}/reset`, {})
}
